import authAxios from '@/shared/axios/auth-axios';
import { AuthToken } from '@/shared/auth/auth-token';

export class AuthService {



  static async signinWithEmailAndPassword(email:string, password:string) {
    const response = await authAxios.post('/auth/sign-in', {
      email,
      password,
    });
    return response.data;
  }

  static async fetchMe() {
    const response = await authAxios.get('/auth/me');
    return response.data;
  }

  static signout() {
    AuthToken.set(null, true);
  }

  static async changePassword(email:string, password:string) {
    const body = {
      email,
      password,
    };
    const response = await authAxios.put(
      '/auth/change-password',
      body,
    );
    return response.data;
  }

  
  static async sendPasswordResetEmail(email:string) {
    const response = await authAxios.put(
      '/auth/send-password-reset-email',
      {
        email,
      },
    );
    return response.data;
  }


  static async webpushSubscription(player_id:string, subscribed:boolean) {
    const body = {
      player_id,
      subscribed
    };
    const response = await authAxios.put(
      '/auth/webpush-subscription',
      body,
    );
    return response.data;
  }

}
