
import { defineComponent, ref, PropType, inject } from "vue";
import { Peripheral, Badge, AlarmType } from "@/models/arniaperfetta";
import { PeripheralService } from "@/services/peripheral-service";
import { alertController, AlertInput } from "@ionic/vue";
import { i18n } from "@/i18n";
import { useStore, MutationTypes, ActionTypes } from "@/store";
import EventBus from "@/shared/event";

export default defineComponent({
  name: "peripheral-chips",
  props: {
    item: {
      type: Object as PropType<Peripheral>,
      required: true,
    },
    reset: {
      type: Boolean,
      default: false,
    },

  },
  components: {},
  computed: {
    chips() {
      return PeripheralService.peripheral_chips(this.item);
    },
  },
  methods: {
    async resetAlarms() {
      if (!this.reset) {
        return;
      }

      const inputs: AlertInput[] = [];
      for (const alarm of this.item.alarms) {
        if (alarm.reset) {
          const value = AlarmType[alarm.type].toLowerCase();
          const label = i18n(`chip.${value}`);
          inputs.push({
            type: "checkbox",
            label,
            value,
            checked: alarm.reset,
          });
        }
      }
      if (inputs.length === 0) {
        return;
      }
      const alert = await alertController.create({
        header: i18n("chip_reset_header"),
        inputs,
        buttons: [
          {
            text: i18n("common_cancel"),
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: i18n("common_confirm"),
          },
        ],
      });
      await alert.present();
      const result = await alert.onDidDismiss();
      if (result.data.values && result.role != "cancel") {
        console.log('triggered reset', result.data.values);
        this.bus.trigger("reset-alarms", {
          id: this.item.id,
          alarms: result.data.values,
        });
      }
    },
  },
  setup() {
    const bus: any = inject("emitter");
    const store = useStore();
    return { store, bus };
  },
});
