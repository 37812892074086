
import { Peripheral, Hub, Sentinel, Scale, Meteo, AlarmsConfig, WeightTare, Alarm, AlarmType, Metrics, AlarmStatus, ConnectionStatus, Position, PeripheralType, Thresholds, GlobalConfigurationAlarms, RangeValue, GlobalConfiguration } from '@/models/arniaperfetta';
import { ALARM_TYPES, ICONS, WEIGHT_LAST_HOUR, DEFAULT_CENTER } from '@/models/constants';
import moment from "moment";
import { I18nUtil } from '@/shared/i18n/i18n-util';
import { i18n } from '@/i18n';
export class ModelMapper {


    static decodePeripherals(data: any) {
        const peripherals: Peripheral[] = []
        for (const el of data.rows) {
            let item: Peripheral = new Hub(el.id)
            switch (el.type) {
                case PeripheralType.BHUB:
                    item = this.decodeHub(el)
                    break;
                case PeripheralType.BSENTINEL:
                    item = this.decodeSentinel(el)
                    break;
                case PeripheralType.BMETEO:
                    item = this.decodeMeteo(el)
                    break;
                case PeripheralType.BSCALE:
                    item = this.decodeScale(el)
                    break;
                default:
                    break;
            }
            if (item.id) {
                item.type_name = this.peripheral_type_name(item);
                item.idau = el['idau'];
                item.metrics = new Metrics()
                item.thresholds = new Thresholds();
                this.decode_info(el['info'], item)
                this.decode_app_config(el['app_config'], item)
                this.decode_app_alarm(el['app_alarm'], item)
                this.decode_active_alarm(el['active_alarm'], item)
                this.decode_metrics(el['metrics'], item)
                peripherals.push(item)
            }
        }
        return peripherals;
    }

    static decodeHub(el: any) {
        const item = new Hub(el.id);
        item.queen_marker = el.app_config.color + 1
        //item.parent = el.id
        item.parent = el.parent
        return item;
    }

    static decodeSentinel(el: any) {
        const item = new Sentinel(el.id);
        item.queen_marker = el.app_config.color + 1
        item.parent = el.parent
        return item;
    }

    static decodeMeteo(el: any) {
        const item = new Meteo(el.id);
        item.parent = el.parent
        return item;
    }

    static decodeScale(el: any) {
        const item = new Scale(el.id);
        item.parent = el.parent
        return item;
    }

    static decode_info(info: any, item: Peripheral) {
        if (info) {
            item.timestamp = moment(info.last_connection).toDate()
            if (item.type != PeripheralType.BSCALE) {
                item.metrics.temperature = info.temperature
                item.metrics.humidity = info.humidity
            }
            const lat = parseFloat(info.gps_lat)
            const lng = parseFloat(info.gps_lon)

            if (lat && lng && this.has_lte(item.id)) {
                item.position = new Position(lat, lng)
            }
        }
    }

    static decode_app_config(config: any, item: Peripheral) {
        if (config) {
            item.imei = config.imei
            item.hub_name = config.hub_description
            item.name = config.description
            //item.timestamp = moment(config.last_connection).toDate()

            if (item.type === PeripheralType.BSCALE) {
                const wmin = (config.wmin_threshold || 0) / 1000;
                const wmax = (config.wmax_threshold || 0) / 1000;
                item.thresholds.weight = { lower: wmin, upper: wmax };
                (<Scale>item).tare = this.decode_tare(config)
            }
            else {
                const tmin = config.tmin_threshold || 0
                const tmax = config.tmax_threshold || 0
                const hmin = config.hmin_threshold || 0
                const hmax = config.hmax_threshold || 0
                item.thresholds.temperature = { lower: tmin, upper: tmax }
                item.thresholds.humidity = { lower: hmin, upper: hmax }
            }
            this.decode_monitoring(config, item);

        }
    }

    static decode_monitoring(config: any, item: Peripheral) {
        if (config) {
            item.monitoring.activation = config.monitoring_from;
            item.monitoring.expire = config.monitoring_until;
            item.monitoring.level = config.monitoring;
            item.monitoring.expired = true;
            if (item.monitoring.expire) {
                item.monitoring.expired = moment().isAfter(moment(item.monitoring.expire))
            }
            return item.monitoring
        }
        return undefined
    }

    static decode_tare(config: any): WeightTare {
        const tare: WeightTare = { gross_weight: 0, weight: 0, hives: 0, last_hour: 0, diff: 0, date: undefined }
        if (config.w_date_start_measure) {
            tare.date = moment(config.w_date_start_measure).toDate()
        }
        tare.hives = config.w_num_hives || 0
        tare.weight = config.weight_reference || 0
        tare.last_hour = config.w_time_measure || WEIGHT_LAST_HOUR
        //console.log(tare)
        return tare;
    }


    static decode_app_alarm(config: any, item: Peripheral) {
        if (config) {
            item.alarm_config.heat = !config.temperature_disable
            item.alarm_config.heat_vacation = config.temperature_vacation

            item.alarm_config.tilt = !config.angle_disable
            item.alarm_config.tilt_vacation = config.angle_vacation

            item.alarm_config.motion = !config.motion_disable
            item.alarm_config.motion_vacation = config.motion_vacation

            item.alarm_config.tracking = !config.tracking_disable
            item.alarm_config.tracking_vacation = config.tracking_vacation
        }
    }

    static decode_metrics(config: any, item: Peripheral) {
        if (config) {
            if (item.type === PeripheralType.BSCALE) {
                item.metrics.weight = config.weight_daily.net_weight
                item.metrics.weight_diff = config.weight_daily.weight_diff
                item.metrics.weight_daily = config.weight_daily
                if (config.weight_daily.weight) {
                    (<Scale>item).tare.gross_weight = config.weight_daily.weight;
                    (<Scale>item).tare.diff = parseFloat((config.weight_daily.weight - (<Scale>item).tare.weight).toFixed(3));
                }
            }
            else {
                item.metrics.humidity_min = config.humidity.min_value || 0
                item.metrics.humidity_max = config.humidity.max_value || 0
                item.metrics.temperature_min = config.temperature.min_value || 0
                item.metrics.temperature_max = config.temperature.max_value || 0
            }
        }
    }
    static decode_active_alarm(alarms: any, item: Peripheral) {
        item.alarms = []

        if (alarms) {
            const connected = this.decode_alarm_connection(alarms['connection'])
            if (connected.status === AlarmStatus.NORMAL) {
                item.connection = ConnectionStatus.ONLINE
            }
            else {
                item.connection = ConnectionStatus.OFFLINE
            }
            item.alarms.push(connected);
            if (alarms['battery'] != 254) {
                item.alarms.push(this.decode_alarm_battery('battery', alarms['battery']));
            }
            if (item.type === PeripheralType.BSCALE) {
                if (alarms['weight'] != 254) {
                    item.alarms.push(this.decode_alarm('weight', alarms['weight']));
                }

                if (alarms['batterySibling'] != 254) {
                    item.alarms.push(this.decode_alarm_battery('batterySibling', alarms['batterySibling']));
                }
            }
            else {
                if (alarms['humidity'] != 254) {
                    item.alarms.push(this.decode_alarm_humidity(alarms['humidity']));
                }
                if (alarms['temperature'] != 254) {
                    item.alarms.push(this.decode_alarm_temperature(alarms['temperature']));
                }

            }

            if (alarms['pioggia'] != 254) {
                item.alarms.push(this.decode_alarm_pioggia(alarms['pioggia']))
            }


            delete alarms['pioggia']
            delete alarms['connection']
            delete alarms['battery']
            delete alarms['batterySibling']
            delete alarms['humidity']
            delete alarms['temperature']
            delete alarms['weight']
            delete alarms['source']
            delete alarms['last_update']

            Object.keys(alarms).forEach(key => {
                if (alarms[key] != 254) {
                    item.alarms.push(this.decode_alarm(key, alarms[key]))
                }
            })

            item.status = AlarmStatus.NORMAL
            let found = item.alarms.filter((el) => el.status === AlarmStatus.ALERTED);
            if (found.length > 0) {
                item.status = AlarmStatus.ALERTED
            }
            found = item.alarms.filter((el) => el.status === AlarmStatus.ALARMED);
            if (found.length > 0) {
                item.status = AlarmStatus.ALARMED
            }

        }
    }



    static decode_alarm_pioggia(value: number) {
        const alarm = new Alarm(AlarmType.RAIN, value)
        alarm.icon = ICONS['rain']
        alarm.status = AlarmStatus.NORMAL
        if (value === 15) {
            alarm.status = AlarmStatus.ALARMED
            alarm.color = 'freeze'
        }
        return alarm;
    }

    static decode_alarm_battery(key: string, value: number) {
        const alarm = new Alarm(ALARM_TYPES[key], value)
        if (value === 2) {
            alarm.icon = ICONS['battery_alarmed']
            alarm.status = AlarmStatus.ALARMED
            alarm.color = 'danger'
        }
        else if (value === 1) {
            alarm.icon = ICONS['battery_alerted']
            alarm.status = AlarmStatus.ALERTED
            alarm.color = 'warning'
        }
        else if (value === 0) {
            alarm.icon = ICONS['battery_normal']
            alarm.status = AlarmStatus.NORMAL
            alarm.color = 'success'
        }
        return alarm;
    }

    static decode_alarm_connection(value: number) {
        const alarm = new Alarm(AlarmType.CONNECTED, value)
        switch (value) {
            case 13:
                alarm.icon = ICONS['connection_offline']
                alarm.status = AlarmStatus.ALARMED
                alarm.color = 'danger'
                break;
            case 12:
                alarm.icon = ICONS['connection_offline']
                alarm.status = AlarmStatus.ALERTED
                alarm.color = 'warning'
                break;
            case 11:
            default:
                alarm.icon = ICONS['connection_online']
                alarm.status = AlarmStatus.NORMAL
                alarm.color = 'connection-color'
                break;
        }

        return alarm;
    }

    static decode_alarm_temperature(value: number) {
        const alarm = new Alarm(AlarmType.TEMPERATURE, value)
        switch (value) {
            case 3:
                alarm.icon = ICONS['temperature_high']
                alarm.status = AlarmStatus.ALERTED
                alarm.color = 'warning'
                break;
            case 4:
                alarm.icon = ICONS['temperature_high']
                alarm.status = AlarmStatus.ALARMED
                alarm.color = 'danger'
                break;
            case 5: // not used
                alarm.icon = ICONS['temperature_low']
                alarm.status = AlarmStatus.ALERTED
                alarm.color = 'warning'
                break;
            case 6:
                alarm.icon = ICONS['temperature_low']
                alarm.status = AlarmStatus.ALARMED
                alarm.color = 'danger'
                break;
            default:
                alarm.icon = ICONS['temperature']
                alarm.status = AlarmStatus.NORMAL
                alarm.color = 'success'
                break;
        }
        return alarm;
    }


    static decode_alarm_humidity(value: number) {
        const alarm = new Alarm(AlarmType.HUMIDITY, value)
        switch (value) {
            case 7:
                alarm.icon = ICONS['humidity_high']
                alarm.status = AlarmStatus.ALERTED
                alarm.color = 'warning'
                break;
            case 8: // not mapped
                alarm.icon = ICONS['humidity_high']
                alarm.status = AlarmStatus.ALARMED
                alarm.color = 'danger'
                break;
            case 9:
                alarm.icon = ICONS['humidity_low']
                alarm.status = AlarmStatus.ALERTED
                alarm.color = 'warning'
                break;
            case 10: // not mapped
                alarm.icon = ICONS['humidity_low']
                alarm.status = AlarmStatus.ALARMED
                alarm.color = 'danger'
                break;
            case 14:
                alarm.icon = ICONS['humidity']
                alarm.status = AlarmStatus.NORMAL
                alarm.color = 'freeze'
                break;
            default:
                alarm.icon = ICONS['humidity']
                alarm.status = AlarmStatus.NORMAL
                alarm.color = 'freeze'
                break;
        }
        return alarm;
    }


    static decode_alarm(key: string, value: number) {
        const alarm = new Alarm(ALARM_TYPES[key], value)
        key = AlarmType[alarm.type].toLowerCase();
        alarm.icon = ICONS[key]
        //console.log( key + ' ' + alarm.icon)

        if (value === 2 || value === 18) {
            alarm.status = AlarmStatus.ALARMED
            alarm.reset = value === 18
            alarm.color = 'danger'
        }
        else if (value === 1 || value === 17) {
            alarm.status = AlarmStatus.ALERTED
            alarm.reset = value === 17
            alarm.color = 'warning'
        }
        else if (value === 0) {
            alarm.status = AlarmStatus.NORMAL
            alarm.color = 'success'
        }
        //console.log( key + ' ' + alarm.color)
        return alarm;
    }

    static decode_global_alarm(settings: any): GlobalConfigurationAlarms {
        const config: GlobalConfigurationAlarms = new GlobalConfigurationAlarms()
        config.hub_alarmed = settings.alarm_global_disable === 0
        config.sentinel_alarmed = settings.alarm_hub_sentinel_disable === 0
        config.meteo_alarmed = settings.alarm_meteo_disable === 0
        config.scale_alarmed = settings.alarm_scale_disable === 0
        return config;
    }


    static decode_global_config(settings: any): GlobalConfiguration {
        const config: GlobalConfiguration = settings;
        config.sms_enable = settings.sms_enable > 0;
        return config;
    }

    static encode_global_alarm(config: GlobalConfigurationAlarms): any {
        const data: any = {}
        data.alarm_global_disable = !config.hub_alarmed
        data.alarm_hub_sentinel_disable = !config.sentinel_alarmed
        data.alarm_meteo_disable = !config.meteo_alarmed
        data.alarm_scale_disable = !config.scale_alarmed
        console.log(JSON.stringify(data))
        return data;
    }

    static encode_alarm_config(config: AlarmsConfig): any {
        const data: any = {}
        data.temperature_disable = !config.heat
        data.temperature_vacation = config.heat_vacation
        data.motion_disable = !config.motion
        data.motion_vacation = config.motion_vacation
        data.angle_disable = !config.tilt
        data.angle_vacation = config.tilt_vacation
        data.tracking_disable = !config.tracking
        data.tracking_vacation = config.tracking_vacation
        return data;
    }

    static encode_thresholds(config: Thresholds): any {
        const data: any = {}
        let value: any
        if (config.humidity) {
            value = config.humidity
            data.hmin_threshold = value.lower
            data.hmax_threshold = value.upper
        }
        if (config.temperature) {
            value = config.temperature
            data.tmin_threshold = value.lower
            data.tmax_threshold = value.upper
        }
        if (config.weight) {
            value = config.weight
            data.wmin_threshold = (value.lower || 0) * 1000
            data.wmax_threshold = (value.upper || 0) * 1000
        }
        return data;
    }

    static encode_color(item: any): any {
        const data: any = {}
        if (item.queen_marker) {
            data['color'] = item.queen_marker - 1
        }
        return data;
    }

    static encode_tare(item: any): any {
        const data: any = {}
        if (item.type === PeripheralType.BSCALE) {
            data['w_num_hives'] = (<Scale>item).tare.hives
            data['w_time_measure'] = (<Scale>item).tare.last_hour
        }
        return data;
    }

    static encode_alarm_reset(alarms: string[]): any {
        const data: any = {}
        Object.keys(ALARM_TYPES).forEach(key => {
            const type = AlarmType[ALARM_TYPES[key]].toLowerCase()
            if (alarms.includes(type)) {
                data[key] = 254
            }
        })
        return data;
    }

    static has_lte(source: number): boolean {
        return (source < 10000000) || (source >= 40000000 && source < 50000000) || (source >= 50000000 && source < 60000000);
    }

    static markers(peripherals: Peripheral[]): any[] {
        const markers = []
        //for (const item of peripherals.filter(el => el.id === el.parent)) {
        for (const item of peripherals.filter(el => el.position != undefined)) {
            //const status = this.parent_status(item, peripherals)
            const marker = this.marker(item, item.status);
            if (marker) {
                markers.push(marker)
            }
        }
        // Bug #2013
        if (markers.length === 0) {
            const marker: any = {}
            marker.id = "Arnia Perfetta";
            marker.position = DEFAULT_CENTER
            marker.name = marker.id
            marker.icon = 'assets/icon/favicon.png';
            markers.push(marker)
        }
        return markers;
    }

    // dinamic refresh of map markers
    static decodeMarkers(data: any): any[] {
        const markers = []
        for (const el of data) {
            const lat = parseFloat(el.gps_lat)
            const lng = parseFloat(el.gps_lon)
            if (el.source && lat && lng) {
                const id = el.source
                const position = new Position(lat, lng)
                const icon = 'assets/icon/favicon-danger.png';
                markers.push({ id, position, icon })
            }
        }
        return markers
    }


    static marker(item: Peripheral, status: AlarmStatus) {
        //console.log(`${item.id} ${JSON.stringify(item.position)}`)
        if (item.position) {
            const marker: any = {}
            marker.id = item.id;
            marker.position = item.position
            marker.description = I18nUtil.formatDateTime(item.timestamp)
            marker.name = `${item.hub_name} - ${item.id}`
            marker.icon = 'assets/icon/favicon.png';
            switch (status) {
                case AlarmStatus.ALARMED:
                    marker.icon = 'assets/icon/favicon-danger.png';
                    break;
                case AlarmStatus.ALERTED:
                    marker.icon = 'assets/icon/favicon-warning.png';
                    break;
                default:
                    break;
            }
            return marker
        }
        return undefined;
    }

    static peripheral_type_name(item: Peripheral) {
        let key = '';
        switch (item.type) {
            case PeripheralType.BHUB:
            case PeripheralType.BSENTINEL:
                key = `${PeripheralType[item.type]}`
                break;
            case PeripheralType.BSCALE:
                    if (this.has_lte(item.id)) {
                        key = `BSCALE_H`
    
                    }
                    else {
                        key = `BSCALE_S`
                    }
                    break;
            case PeripheralType.BMETEO:
                if (this.has_lte(item.id)) {
                    key = `BMETEO_H`

                }
                else {
                    key = `BMETEO`
                }
                break;

            default:
                break;
        }
        return i18n(`enum.${key}`)

    }


}
