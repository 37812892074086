
import { AlarmType } from "./arniaperfetta"
import { i18n } from '@/i18n';

export const MAX_HIVES = 6;

export const WEIGHT_LAST_HOUR = 21;

export const ICONS: Record<string, string> = {
    "connection_offline": "assets/mdt-italia/006_offline.svg",
    "connection_online": "assets/mdt-italia/005_online.svg",
    "battery": "assets/mdt-italia/007_battery_full.svg",
    "battery_sibling": "assets/mdt-italia/007_battery_full.svg",
    "battery_normal": "assets/mdt-italia/007_battery_full.svg",
    "battery_alerted": "assets/mdt-italia/008_battery_half.svg",
    "battery_alarmed": "assets/mdt-italia/009_battery_empty.svg",
    "weight": "assets/mdt-italia/024_alert_weight.svg",
    "honey_extraction": "assets/mdt-italia/023_alert_honey_extraction.svg",
    "nutrition": "assets/mdt-italia/022_alert_nutrition.svg",
    "treatment": "assets/mdt-italia/025_alert_treatment.svg",
    "swarming": "assets/mdt-italia/026_alert_swarming.svg",
    "health": "assets/mdt-italia/027_alert_health.svg",
    "wind": "assets/mdt-italia/028_alert_wind.svg",
    "rain": "assets/mdt-italia/029_alert_rain.svg",
    "sunlight": "assets/mdt-italia/030_alert_few_sun.svg",
    "alarm": "assets/mdt-italia/012_alarm_on.svg",
    "tilt": "assets/mdt-italia/014_alarm_tilt.svg",
    "motion": "assets/mdt-italia/013_alarm_motion.svg",
    "tracking": "assets/mdt-italia/015_alarm_tracking.svg",
    "temperature": "assets/mdt-italia/018_alarm_temp.svg",
    "temperature_high": "assets/mdt-italia/016_alarm_temp_high.svg",
    "temperature_low": "assets/mdt-italia/017_alarm_temp_low.svg",
    "humidity": "assets/mdt-italia/019_alarm_hum.svg",
    "humidity_low": "assets/mdt-italia/020_alarm_hum_low.svg",
    "humidity_high": "assets/mdt-italia/020_alarm_hum_high.svg",
    "super_add": "assets/mdt-italia/021_super_add.svg",
    "note": "assets/mdt-italia/041_Memo Agenda.svg",
}

export const COLORS = ['BLUE', 'WHITE', 'YELLOW', 'RED', 'GREEN', 'PURPLE', 'BLACK']
export const TAGS = ['NUTRITION', 'HEALTH', 'TREATMENT', 'HONEY_EXTRACTION']

export const ALARM_TYPES: Record<string, AlarmType> = {
    'connection': AlarmType.CONNECTION,
    'alarm': AlarmType.ALARM,
    'note': AlarmType.NOTE,
    'angle': AlarmType.TILT,
    'movements': AlarmType.MOTION,
    'tracking': AlarmType.TRACKING,
    'battery': AlarmType.BATTERY,
    'batterySibling': AlarmType.BATTERY_SIBLING,
    'humidity': AlarmType.HUMIDITY,
    'temperature': AlarmType.TEMPERATURE,
    'weight': AlarmType.WEIGHT,
    'nutrimento': AlarmType.NUTRITION,
    'aggiungimelario': AlarmType.SUPER_ADD,
    'smielatura': AlarmType.HONEY_EXTRACTION,
    'sciamatura': AlarmType.SWARMING,
    'trattamenti': AlarmType.TREATMENT,
    'salute': AlarmType.HEALTH,
    'pocosole': AlarmType.SUNLIGHT,
    'vento': AlarmType.WIND,
    'pioggia': AlarmType.RAIN,
}


function label(name: string) {
    return i18n(`export.fields.${name}`);
}

const red = "#eb445a";
const cyan = "#01ccff";
const yellow = "#ffc409";
const green = "#2dd36f";

export const param1 = { name: "temperature", label: label("temperature"), color: red, type: "line" };
export const param2 = { name: "humidity", label: label("humidity"), color: cyan, type: "line" };
export const param3 = { name: "temperature_ext", label: label("temperature_ext"), color: green, type: "line" };
export const param4 = { name: "humidity_ext", label: label("humidity_ext"), color: 'blue', type: "line" };
export const param5 = { name: "wmetric_net", label: label("weight"), color: yellow, type: "bar" };
export const param6 = { name: "wmetric1", label: label("wmetric1"),  color: yellow, type: "bar" };
export const param7 = { name: "wmetric2", label: label("wmetric2"), color: yellow, type: "bar", positive:green, negative:red };
export const param8 = { name: "wmetric3", label: label("wmetric3"), color: 'orange', type: "line", fill: true, };

export const DEFAULT_CENTER = { lat: 45.4698, lng: 7.9048 };

