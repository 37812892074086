
import router from '@/router';
import { InjectionKey } from 'vue';
import { createStore, createLogger, useStore as baseUseStore, Store, MutationTree, ActionTree, GetterTree } from 'vuex';
import { Peripheral, PeripheralType, Note, GlobalConfiguration, Scale, GlobalConfigurationAlarms } from "@/models/arniaperfetta";
import { MetricsService } from '@/services/metrics-service';
import { PeripheralService } from '@/services/peripheral-service';
import { NoteService } from '@/services/note-service';
import { SettingsService } from '@/services/settings-service';
import { AuthService } from '@/services/auth-service';
import { AuthToken } from '@/shared/auth/auth-token';
import { alertController, modalController } from "@ionic/vue";
import { i18n, getLanguageCode, getTimeZone } from "@/i18n";
import { ModelMapper } from '@/services/model-mapper'
import NameModal from "@/components/NameModal.vue";
import moment from 'moment';
import Exporter from '@/shared/exporter/exporter';
import lodash from 'lodash';
import { Utils } from "@/shared/utils";

export const key: InjectionKey<Store<State>> = Symbol();

export type State = {
    owner: boolean;
    config: GlobalConfiguration;
    notes: Note[];
    fetch_notes: Note[];
    peripherals: Peripheral[];
    error: any;
    currentUser: any;
    loadingInit: boolean;
    connected: boolean;
    isMobile: boolean;
    firstLogin: boolean;
    progress: boolean;
    peripheral: Peripheral | undefined;
    range: Date[];
    bounds: string[];
    datasets: any;
    sources: any;
    markers: any;
    center: any;
}

const state: State = {
    owner:false,
    config: {} as GlobalConfiguration,
    peripherals: [],
    notes: [],
    fetch_notes: [],
    error: undefined,
    currentUser: undefined,
    isMobile: false,
    loadingInit: false,
    connected: false,
    progress: false,
    firstLogin: false,
    datasets: {},
    sources: {},
    peripheral: undefined,
    range: [],
    bounds: [],
    markers: [],
    center: undefined,
}

export const enum MutationTypes {
    FIRST_LOGIN = 'FIRST_LOGIN',
    IS_MOBILE = 'IS_MOBILE',

    CONNECTED = 'CONNECTED',
    ERROR = 'ERROR',
    PROGRESS = 'PROGRESS',

    AUTH_START = 'AUTH_START',
    AUTH_SUCCESS = 'AUTH_SUCCESS',
    AUTH_ERROR = 'AUTH_ERROR',

    LOADING_INIT = 'LOADING_INIT',
    AUTH_INIT_START = 'AUTH_INIT_START',
    AUTH_INIT_SUCCESS = 'AUTH_INIT_SUCCESS',
    AUTH_INIT_ERROR = 'AUTH_INIT_ERROR',
    BACKEND_ERROR = 'BACKEND_ERROR',
    PERIPHERALS = 'PERIPHERALS',
    EDIT_PERIPHERAL = 'EDIT_PERIPHERAL',
    CONFIG = 'CONFIG',
    NOTES = 'NOTES',
    FETCH_NOTES = 'FETCH_NOTES',
    ADD_NOTE = 'ADD_NOTE',
    DEL_NOTE = 'DEL_NOTE',
    EDIT_NOTE = 'EDIT_NOTE',
    DATASETS = 'DATASETS',
    SOURCES = 'SOURCES',
    RANGE = 'RANGE',
    BOUNDS = 'BOUNDS',
    PERIPHERAL = 'PERIPHERAL',
    MARKERS = 'MARKERS',
    CENTER = 'CENTER',
    TRACKING = 'TRACKING',
}


const mutations: MutationTree<State> = {
    [MutationTypes.MARKERS](state: State, payload: any) {
        state.markers = payload;
    },

    [MutationTypes.TRACKING](state: State, markers: any) {
        for (const marker of markers) {
            const item = state.markers.find((el: { id: any; }) => el.id === marker.id);
            if (item) {
                item.icon = marker.icon
                item.position = marker.position
            }
        }
    },

    [MutationTypes.CENTER](state: State, value: any) {
        state.center = value;
    },

    [MutationTypes.IS_MOBILE](state: State, value: any) {
        state.isMobile = value;
    },

    [MutationTypes.FIRST_LOGIN](state: State, value: any) {
        state.firstLogin = value;
    },


    [MutationTypes.SOURCES](state: State, value: any) {
        state.sources = value;
    },
    [MutationTypes.BOUNDS](state: State, value: any) {
        state.bounds = value;
    },

    [MutationTypes.RANGE](state: State, value: any) {
        state.range = value;
    },

    [MutationTypes.PERIPHERAL](state: State, value: any) {
        state.peripheral = value;
    },

    [MutationTypes.LOADING_INIT](state: State, loadingInit: boolean) {
        state.loadingInit = loadingInit;
    },
    [MutationTypes.CONNECTED](state: State, connected: boolean) {
        state.connected = connected;
    },
    [MutationTypes.PROGRESS](state: State, progress: boolean) {
        state.progress = progress;
        if (progress) {
            state.error = undefined;
        }
    },

    [MutationTypes.EDIT_PERIPHERAL](state: State, payload: Peripheral) {
        const item = state.peripherals.find(item => item.id === payload.id);
        if (item) {
            Object.assign(item, payload);
        }
    },

    [MutationTypes.ADD_NOTE](state: State, item: Note) {
        state.notes.push({ ...item });
        state.fetch_notes.push({ ...item });
    },

    [MutationTypes.DEL_NOTE](state: State, item: Note) {
        state.notes.splice(state.notes.indexOf(item), 1);
        state.fetch_notes.splice(state.fetch_notes.indexOf(item), 1);
    },

    [MutationTypes.EDIT_NOTE](state: State, payload: Note) {
        let item = state.notes.find(item => item.id === payload.id);
        if (item) {
            Object.assign(item, payload);
        }
        item = state.fetch_notes.find(item => item.id === payload.id);
        if (item) {
            Object.assign(item, payload);
        }
    },
    [MutationTypes.CONFIG](state: State, payload: GlobalConfiguration) {
        if (payload.top_card_until) {
            payload.top_card_valid = moment().isAfter(moment(payload.top_card_until));
        }
        else {
            payload.top_card_valid = false;
        }
        state.config = payload
    },
    [MutationTypes.PERIPHERALS](state: State, payload: Peripheral[]) {
        state.peripherals = payload

        if (state.currentUser) {
            const items = state.peripherals.filter(el => el.idau === state.currentUser.idau);
            state.owner = items.length > 0;
        }

    },
    [MutationTypes.NOTES](state: State, payload: Note[]) {
        state.notes = payload
    },
    [MutationTypes.FETCH_NOTES](state: State, payload: Note[]) {
        state.fetch_notes = payload
    },
    [MutationTypes.AUTH_START](state: State) {
        state.currentUser = null
        state.error = null
    },
    [MutationTypes.AUTH_SUCCESS](state: State, payload: any) {
        state.currentUser = payload.currentUser || null;
    },
    [MutationTypes.AUTH_ERROR](state: State, error: any) {
        state.currentUser = null
        state.error = error
    },
    [MutationTypes.AUTH_INIT_START](state: State) {
        state.currentUser = null
        state.error = null
    },
    [MutationTypes.AUTH_INIT_SUCCESS](state: State, payload: any) {
        state.currentUser = payload.currentUser || null;
        state.loadingInit = false
        state.error = null
    },
    [MutationTypes.AUTH_INIT_ERROR](state: State, error: any) {
        state.currentUser = null
        state.error = error

    },
    [MutationTypes.BACKEND_ERROR](state: State, error: any) {
        state.loadingInit = false
        state.error = error
    },
    [MutationTypes.ERROR](state: State, error: any) {
        state.error = error
        state.progress = false
    },

    [MutationTypes.DATASETS](state: State, payload: any) {
        state.datasets = payload
    },

};


export const enum ActionTypes {
    SOCKET_reload = 'SOCKET_reload',
    SOCKET_heartbeat = 'SOCKET_heartbeat',
    SOCKET_connect = 'SOCKET_connect',
    SOCKET_disconnect = 'SOCKET_disconnect',
    PERIPHERALS = 'PERIPHERALS',
    PERIPHERAL = 'PERIPHERAL',
    NOTES = 'NOTES',
    FETCH_DATASETS = 'FETCH_DATASETS',
    EXPORT_DATASETS = 'EXPORT_DATASETS',
    FETCH_NOTES = 'FETCH_NOTES',
    FETCH_EVENTS = 'FETCH_EVENTS',
    CONFIG = 'CONFIG',
    doResize = 'doResize',
    doAuthInit = 'doAuthInit',
    doInit = 'doInit',
    doWaitUntilInit = 'doWaitUntilInit',
    doSigninWithEmailAndPassword = 'doSigninWithEmailAndPassword',
    doSignout = 'doSignout',
    doUpdateGlobalConfig = 'doUpdateGlobalConfig',
    doUpdateGlobalConfigAlarms = 'doUpdateGlobalConfigAlarms',
    doUpdateName = 'doUpdateName',
    doUpdateColor = 'doUpdateColor',
    doUpdateAlarmsConfig = 'doUpdateAlarmsConfig',
    doUpdateThresholds = 'doUpdateThresholds',
    doUpdateTare = 'doUpdateTare',
    doUpdatePeripheralName = 'doUpdatePeripheralName',
    doCreateNote = 'doCreateNote',
    doUpdateNote = 'doUpdateNote',
    doDeleteNote = 'doDeleteNote',
    doMonitoring = 'doMonitoring',
    handleMonitoring = 'handleMonitoring',
    doResetAlarms = 'doResetAlarms',
    doForgotPassword = 'doForgotPassword',
    doChangePassword = 'doChangePassword',
    doChangeRange = 'doChangeRange',
    doWebpushSubscription = 'doWebpushSubscription',
    doTracking = 'doTracking',
    doUpdateHubNames = 'doUpdateHubNames',
}


const actions: ActionTree<State, any> = {

    async [ActionTypes.doResize]({ state, commit, dispatch }, { width, height }) {
        commit(MutationTypes.IS_MOBILE, width < 576);
    },

    async [ActionTypes.SOCKET_reload]({ }) {
        window.location.reload();
    },
    async [ActionTypes.SOCKET_heartbeat]({ state, commit, dispatch }, payload: any) {
        if (state.currentUser && !state.loadingInit) {
            if (payload && payload.version != i18n('version')) {
                window.location.reload();
            }
            else {
                await dispatch(ActionTypes.doInit);
            }

        }
    },
    async [ActionTypes.SOCKET_connect]({ state, commit, dispatch }) {
        commit(MutationTypes.CONNECTED, true);
        if (!state.loadingInit) {
            dispatch(ActionTypes.doAuthInit)
        }
    },
    async [ActionTypes.SOCKET_disconnect]({ state, commit, dispatch }) {
        commit(MutationTypes.CONNECTED, false);
        commit(MutationTypes.LOADING_INIT, false);
    },
    async [ActionTypes.PERIPHERALS]({ commit }) {
        const peripherals: Peripheral[] = await PeripheralService.list();
        commit(MutationTypes.PERIPHERALS, peripherals);
        const markers: any[] = ModelMapper.markers(peripherals);
        const center = Utils.center_point(markers.map((o) => o.position));
        commit(MutationTypes.MARKERS, markers);
        commit(MutationTypes.CENTER, center);
    },

    async [ActionTypes.doTracking]({ commit }) {
        commit(MutationTypes.PROGRESS, true);
        const markers: any[] = await PeripheralService.markers();
        commit(MutationTypes.TRACKING, markers);
        commit(MutationTypes.PROGRESS, false);
    },


    async [ActionTypes.NOTES]({ commit, state }) {
        const start = moment().startOf('day').toDate();
        const filter: any = {};
        filter.timestampRange = [start, undefined]
        // if (state.currentUser) {
        //     filter.idau = state.currentUser.idau
        // }

        if (!state.owner) {
            filter.source = state.peripherals.map((o) => o.id)
        }
        
        const response = await NoteService.list(filter, undefined, undefined, undefined);
        const notes: Note[] = response.rows;
        commit(MutationTypes.NOTES, notes);
    },

    async [ActionTypes.CONFIG]({ commit }) {
        try {
            let config: GlobalConfiguration = await SettingsService.findGlobalConfiguration();
            if (config.language != getLanguageCode()) {
                const data: any = {}
                data['language'] = getLanguageCode()
                config = await SettingsService.updateGlobalConfiguration(data);
            }
            commit(MutationTypes.CONFIG, config);
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
    },

    async [ActionTypes.doAuthInit]({ state, commit, dispatch }) {
        const token = AuthToken.get();
        if (token) {
            commit(MutationTypes.AUTH_INIT_START);
            try {
                const currentUser = await AuthService.fetchMe();
                if (currentUser) {
                    commit(MutationTypes.AUTH_INIT_SUCCESS, { currentUser });
                    await dispatch(ActionTypes.doInit);
                }
                else {
                    await AuthService.signout();
                    commit(MutationTypes.AUTH_INIT_ERROR, new Error('auth.expired_token'));
                }
            } catch (error) {
                commit(MutationTypes.BACKEND_ERROR, error);
                // NO OFFLINE
                if (!state.connected) {
                    store.dispatch(ActionTypes.doSignout);
                }
            }
        }
    },

    async [ActionTypes.doInit]({ commit, dispatch }) {
        const token = AuthToken.get();
        if (token) {
            commit(MutationTypes.LOADING_INIT, true);
            try {
                await dispatch(ActionTypes.PERIPHERALS, { filter: {}, orderBy: undefined, limit: undefined, offset: 0 });
                await dispatch(ActionTypes.NOTES, { filter: {}, orderBy: undefined, limit: undefined, offset: 0 });
                await dispatch(ActionTypes.CONFIG);
            } catch (error) {
                commit(MutationTypes.BACKEND_ERROR, error);
            }
            commit(MutationTypes.LOADING_INIT, false);
        }
    },

    async [ActionTypes.doWaitUntilInit]({ state }) {
        if (!state.loadingInit && state.currentUser) {
            return Promise.resolve();
        }
        return new Promise<void>((resolve) => {
            const waitUntilInitInterval = setInterval(() => {
                if (!state.loadingInit && state.currentUser) {
                    clearInterval(waitUntilInitInterval);
                    resolve();
                }
            }, 2000);
        });
    },

    async [ActionTypes.doSignout]({ commit }) {
        try {
            commit('AUTH_START');
            await AuthService.signout();
            commit('AUTH_SUCCESS', {
                currentUser: null,
            });
            router.push('/logout');
        } catch (error) {
            commit('AUTH_ERROR', error);
        }
    },

    async [ActionTypes.doSigninWithEmailAndPassword](
        { commit, dispatch },
        { email, password, rememberMe },
    ) {
        let currentUser = null;
        let token = null;
        commit(MutationTypes.PROGRESS, true);
        commit(MutationTypes.FIRST_LOGIN, false);
        try {
            commit(MutationTypes.AUTH_START);
            
            token = await AuthService.signinWithEmailAndPassword(
                email,
                password,
            );
            if (token) {
                AuthToken.set(token, rememberMe);
                currentUser = await AuthService.fetchMe();
                commit(MutationTypes.AUTH_SUCCESS, { currentUser });
                await dispatch(ActionTypes.doInit);
            }
            else {
                commit(MutationTypes.FIRST_LOGIN, true);
            }

        } catch (error) {
            await AuthService.signout();
            commit(MutationTypes.AUTH_ERROR, error);
        }
        commit(MutationTypes.PROGRESS, false);

    },

    async [ActionTypes.doForgotPassword](
        { commit, dispatch },
        { email},
    ) {
        commit(MutationTypes.PROGRESS, true);
        try {
            await AuthService.sendPasswordResetEmail(
                email,
            );
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
        commit(MutationTypes.PROGRESS, false);

    },


    async [ActionTypes.doChangePassword](
        { commit, dispatch },
        { email, password },
    ) {
        commit(MutationTypes.PROGRESS, true);
        try {
            await AuthService.changePassword(
                email,
                password,
            );
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
        commit(MutationTypes.PROGRESS, false);

    },

    async [ActionTypes.doUpdateGlobalConfigAlarms]({ commit }, alarms: GlobalConfigurationAlarms) {
        commit(MutationTypes.PROGRESS, true);
        try {
            const payload: any = ModelMapper.encode_global_alarm(alarms);
            const config: GlobalConfiguration = await SettingsService.updateGlobalConfiguration(payload);
            commit(MutationTypes.CONFIG, config);
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
        commit(MutationTypes.PROGRESS, false);
    },

    async [ActionTypes.doUpdateGlobalConfig]({ commit }, payload) {
        commit(MutationTypes.PROGRESS, true);
        try {
            const config: GlobalConfiguration = await SettingsService.updateGlobalConfiguration(payload);
            commit(MutationTypes.CONFIG, config);
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
        commit(MutationTypes.PROGRESS, false);
    },

    async [ActionTypes.doUpdateHubNames]({ commit }, { payload }) {
        commit(MutationTypes.PROGRESS, true);
        try {
            Object.keys(payload)
                .forEach(async function (key, i) {
                    const id = parseInt(key)
                    await PeripheralService.updatePeripheralName(id, { hub_name: payload[key] });
                })
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
        commit(MutationTypes.PROGRESS, false);
    },

    async [ActionTypes.doUpdatePeripheralName]({ commit }, { item, payload }) {
        commit(MutationTypes.PROGRESS, true);
        try {
            await PeripheralService.updatePeripheralName(item.id, payload);
            commit(MutationTypes.EDIT_PERIPHERAL, item);
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
        commit(MutationTypes.PROGRESS, false);
    },


    async [ActionTypes.doUpdateName]({ commit }, { item, is_hub }) {
        const modal = await modalController.create({
            component: NameModal,
            cssClass: "alert-name-class",
            componentProps: { item, is_hub },
        });
        return modal.present();
    },


    async [ActionTypes.doUpdateColor]({ commit }, item) {
        commit(MutationTypes.PROGRESS, true);
        try {
            await PeripheralService.updatePeripheralColor(item);
            commit(MutationTypes.EDIT_PERIPHERAL, item);
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
        commit(MutationTypes.PROGRESS, false);
    },

    async [ActionTypes.doUpdateAlarmsConfig]({ commit }, item) {
        commit(MutationTypes.PROGRESS, true);
        try {
            await PeripheralService.updatePeripheralAlarmsConfig(item.id, item.alarm_config);
            commit(MutationTypes.EDIT_PERIPHERAL, item);
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
        commit(MutationTypes.PROGRESS, false);
    },

    async [ActionTypes.doResetAlarms]({ commit }, { item, alarms }) {
        commit(MutationTypes.PROGRESS, true);
        try {
            await PeripheralService.resetPeripheralAlarms(item, alarms);
            commit(MutationTypes.EDIT_PERIPHERAL, item);
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
        commit(MutationTypes.PROGRESS, false);
    },


    async [ActionTypes.doUpdateThresholds]({ commit }, item) {
        commit(MutationTypes.PROGRESS, true);
        try {
            await PeripheralService.updatePeripheralThresholds(item.id, item.thresholds);
            commit(MutationTypes.EDIT_PERIPHERAL, item);
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
        commit(MutationTypes.PROGRESS, false);

    },

    async [ActionTypes.doUpdateTare]({ commit }, item) {
        commit(MutationTypes.PROGRESS, true);
        try {
            const value = await PeripheralService.updatePeripheralTare(item);
            item.tare = ModelMapper.decode_tare(value);
            commit(MutationTypes.EDIT_PERIPHERAL, item);
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
        commit(MutationTypes.PROGRESS, false);
    },

    async [ActionTypes.doCreateNote]({ commit }, data) {
        commit(MutationTypes.PROGRESS, true);
        try {
            const record = await NoteService.create(data);
            commit(MutationTypes.ADD_NOTE, record);

        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
        commit(MutationTypes.PROGRESS, false);
    },

    async [ActionTypes.doDeleteNote]({ commit }, data) {
        commit(MutationTypes.PROGRESS, true);
        try {
            await NoteService.destroyAll([data.id]);
            commit(MutationTypes.DEL_NOTE, data);
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
        commit(MutationTypes.PROGRESS, false);
    },

    async [ActionTypes.doUpdateNote]({ commit }, data) {
        commit(MutationTypes.PROGRESS, true);
        try {
            const record = await NoteService.update(data.id, data);
            commit(MutationTypes.EDIT_NOTE, record);
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
        commit(MutationTypes.PROGRESS, false);
    },

    async [ActionTypes.FETCH_NOTES]({ commit, state }, { filter = {}, orderBy = null, limit = null, offset = null }) {
        if (!state.owner) {
            filter.source = state.peripherals.map((o) => o.id)
        }
        let notes: Note[] = [];
        commit(MutationTypes.FETCH_NOTES, notes);
        commit(MutationTypes.LOADING_INIT, true);
        try {


            const response = await NoteService.list(filter, orderBy, limit, offset);
            notes = response.rows;
            commit(MutationTypes.FETCH_NOTES, notes);
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
        commit(MutationTypes.LOADING_INIT, false);
        return notes;
    },

    async [ActionTypes.FETCH_EVENTS]({ commit, state }, { start, end }) {
        const events = [];
        commit(MutationTypes.PROGRESS, true);
        try {
            const filter: any = {}
            filter.timestampRange = [start, end];
            
            if (!state.owner) {
                filter.source = state.peripherals.map((o) => o.id)
            }
            const response = await NoteService.list(filter, null, null, null);
            for (const row of response.rows) {
                const id = row.id;
                //const url = `/peripheral/${row.source}/details`;
                const tags = I18nUtil.tags_render(row.tags || [])
                const description = row.description || '';
                const title = `[${row.source}] ${row.title}`;
                const start = row.timestamp;
                //const color = row.status === 'new' ? '#27488D' : '#409EFF'
                events.push({ id, title, start, description, tags });
            }
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
        commit(MutationTypes.PROGRESS, false);
        return events;
    },

    async [ActionTypes.handleMonitoring]({ dispatch }, item: Peripheral) {

        const alert = await alertController.create({
            cssClass: "alert-class",
            header: i18n("charts_monitoring_action"),
            message: i18n("common_areyousure"),
            buttons: [
                {
                    text: i18n("common_no"),
                    role: "cancel",
                    cssClass: "secondary",
                    id: "cancel-button",
                    handler: () => { },
                },
                {
                    text: i18n("common_yes"),
                    id: "confirm-button",
                    handler: () => {
                        dispatch(ActionTypes.doMonitoring, item);
                    },
                },
            ],
        });
        return alert.present();
    },


    async [ActionTypes.doMonitoring]({ commit }, item: Peripheral) {
        commit(MutationTypes.PROGRESS, true);
        try {
            const record = await PeripheralService.updatePeripheralMonitoring(item.id, {});
            ModelMapper.decode_monitoring(record, item);
            commit(MutationTypes.EDIT_PERIPHERAL, item);
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
        commit(MutationTypes.PROGRESS, false);
    },


    async [ActionTypes.PERIPHERAL]({ commit, state, dispatch }, { id }) {
        await dispatch(ActionTypes.doWaitUntilInit);
        commit(MutationTypes.PERIPHERAL, undefined);
        const peripheral = state.peripherals.find((el) => el.id === parseInt(id))
        if (peripheral) {
            try {
                // utc to local
                const end = moment(peripheral.timestamp).endOf('day').toDate();
                const start = moment(peripheral.timestamp).startOf('day').add(-7, 'days').toDate();
                // if (!peripheral.monitoring.expired && peripheral.monitoring.activation) {
                //     commit(MutationTypes.BOUNDS, [peripheral.monitoring.activation, end]);
                // }
                // else {
                //     commit(MutationTypes.BOUNDS, [start, end]);
                // }

                const meteo: Peripheral = store.state.peripherals.find(
                    (el) =>
                        el.type === PeripheralType.BMETEO && el.parent === peripheral.parent
                )!;
                const range: any = [start, end];
                const sources: any = MetricsService.sources(peripheral, meteo);

                let lower = moment(peripheral.timestamp).add(-3, 'months').endOf('day').toDate()
                
                if (peripheral.monitoring.activation) {
                    lower = moment(peripheral.monitoring.activation).startOf('day').toDate()
                }
                else {
                    lower = moment('2020-01-01').endOf('day').toDate()
                }


                let upper =  moment(peripheral.timestamp).endOf('day').toDate()
                await dispatch(ActionTypes.FETCH_DATASETS, { sources, range });
                commit(MutationTypes.PERIPHERAL, peripheral);
                commit(MutationTypes.BOUNDS, [lower, upper]);
                commit(MutationTypes.RANGE, range);
                commit(MutationTypes.SOURCES, sources);


            } catch (error) {
                commit(MutationTypes.ERROR, error);
            }
            return peripheral;
        }
    },

    async [ActionTypes.doChangeRange]({ commit, state, dispatch }, { range }) {
        const [start, end] = state.range;
        const lower = moment(range[0]).startOf('day').toDate();
        const upper = moment(range[1]).startOf('day').toDate();
        if (lower && lower.getTime() != start.getTime() || upper && upper.getTime() != end.getTime()) {
            const sources = state.sources;
            await dispatch(ActionTypes.FETCH_DATASETS, { sources, range });
            commit(MutationTypes.RANGE, [lower, upper]);
        }
    },

    async [ActionTypes.FETCH_DATASETS]({ commit, state }, { range = [], sources = [] }) {
        commit(MutationTypes.DATASETS, {});
        commit(MutationTypes.PROGRESS, true);
        try {
            const datasets: any = {};
            const acquisitionRange: any = [moment(range[0]).format("YYYY-MM-DD"), moment(range[1]).format("YYYY-MM-DD")];
            for (let { id, metric, name } of sources) {
                const filter = {
                    acquisitionRange,
                    idau: state.currentUser.idau,
                    source: id,
                    metric
                }
                if (!name) {
                    name = metric
                }

                const response = await MetricsService.list(filter, null, null, null)
                //console.log(response.dataset)

                // Bug #1923 i timestamp dei grafici devono essere convertiti da UTC a local time
                const offset = new Date().getTimezoneOffset() * 60 * 1000;
                const array = response.dataset.map((obj: any) => {
                    obj.ts = obj.ts + offset;
                    return obj;
                });
                // { x, y, h, ts }
                datasets[name] = array;

                //datasets[name] = response.dataset;

                if (metric === 'weight') {
                    // { x, y, h, dw, a, ts }
                    const item = state.peripherals.find(p => p.id === id);
                    const { wmetric1, wmetric2, wmetric3, wmetric_net } = MetricsService.calculate_weight_metrics(datasets[metric], item as Scale, acquisitionRange);
                    datasets['wmetric1'] = wmetric1;
                    datasets['wmetric2'] = wmetric2;
                    datasets['wmetric3'] = wmetric3;
                    datasets['wmetric_net'] = wmetric_net;
                }

            }
            commit(MutationTypes.DATASETS, datasets);
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
        commit(MutationTypes.PROGRESS, false);
    },


    async [ActionTypes.EXPORT_DATASETS]({ commit, state }, { timeframe }) {
        commit(MutationTypes.PROGRESS, true);
        try {
            const { fields, rows } = await MetricsService.export(state.peripheral, state.datasets, timeframe);
            new Exporter(
                fields,
                'datasets',
            ).transformAndExportAsExcelFile(rows);
        } catch (error) {
            commit(MutationTypes.ERROR, error);
        }
        commit(MutationTypes.PROGRESS, false);
    },

    async [ActionTypes.doWebpushSubscription]({ commit, state }, { onesignal }) {
        if (onesignal) {
            try {
                const player_id = await onesignal.getUserId();
                if (player_id) {
                    const subscribed = await onesignal.isPushNotificationsEnabled();
                    await AuthService.webpushSubscription(player_id, subscribed);
                    const extId = await onesignal.getExternalUserId();
                    if (!extId && subscribed) {
                        await onesignal.setExternalUserId(state.currentUser.idau);
                    }
                    if (extId && !subscribed) {
                        await onesignal.removeExternalUserId();
                    }
                }
            } catch (error) {
                commit(MutationTypes.ERROR, error);
            }

        }
    },



}

// getters types
export type Getters = {
    currentUser(state: State): any
}

// getters
export const getters: GetterTree<State, any> & Getters = {
    currentUser: (state) => {
        return state.currentUser;
    },
    monitoring: (state) => {
        return state.peripheral?.monitoring.expired;
    },
    owner: (state) => {
        console.log("owner")
        if (state.peripheral) {
            return state.peripheral?.idau ===  state.currentUser.idau;
        }
        const items = state.peripherals.filter(el => el.idau === state.currentUser.idau);
        if (state.currentUser) {
            console.log(state.currentUser.idau)
        }
        console.log(state.peripherals.length)
        return items.length > 0;
    }

}



// Plug in logger when in development environment
const debug = process.env.NODE_ENV !== 'production';
const plugins = debug ? [createLogger({})] : [];

// https://github.com/robinvdvleuten/vuex-persistedstate
// unmantained but support Vuex 4

// https://github.com/championswimmer/vuex-persist
// current release still not working with Vuex 4

import { Storage } from '@capacitor/storage';
import createPersistedState from 'vuex-persistedstate';
import { I18nUtil } from '@/shared/i18n/i18n-util';
//import { useRouter } from 'vue-router';

// Plug in session storage based persistence
// plugins.push(createPersistedState({ storage: window.sessionStorage }));
plugins.push(createPersistedState({ paths: ['config'], storage: window.localStorage }));

export const store = createStore<State>({ plugins, state, mutations, actions, getters });
// our own useStore function for types
export function useStore() {
    return baseUseStore(key);
}
