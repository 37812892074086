import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_peripheral_connection = _resolveComponent("peripheral-connection")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    button: "",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.details())),
    detail: "",
    class: _normalizeClass(_ctx.item_class)
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, { class: "ion-align-items-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, { size: "2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    src: "assets/mdt-italia/001_bhub.svg",
                    color: "primary",
                    class: "custom_icon"
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, { size: "10" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_peripheral_connection, {
                      item: _ctx.item,
                      hub_name: ""
                    }, null, 8, ["item"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}