import * as yup from 'yup';
import moment from 'moment';
import GenericField from '@/shared/fields/generic-field';
import { i18n } from '@/i18n';

export default class DateTimeField extends GenericField {

  constructor(
    name: string,
    label: string,
    config: {
      placeholder?: string;
      hint?: string;
      required?: boolean;
    } = {},
  ) {
    super(name, label);


  }

  forPresenter(value: moment.MomentInput) {
    return value
      ? moment(value).format('YYYY-MM-DD HH:mm')
      : null;
  }

  forExport() {
    return yup.mixed().label(this.label);
  }

}
