import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "i18n-flags" }
const _hoisted_2 = ["alt", "src", "title", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language) => {
      return (_openBlock(), _createElementBlock("img", {
        alt: language.label,
        key: language.id,
        src: language.flag,
        title: language.label,
        onClick: ($event: any) => (_ctx.doChangeLanguage(language.id))
      }, null, 8, _hoisted_2))
    }), 128))
  ]))
}