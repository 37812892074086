import mapKeys from 'lodash/mapKeys';
import ExporterSchema from '@/shared/exporter/exporter-schema';
import { Excel } from '@/shared/excel/excel';

export default class Exporter {
  schema: ExporterSchema;
  excelFileName: string;

  constructor(fields: any, excelFileName: string) {
    this.schema = new ExporterSchema(fields);
    this.excelFileName = excelFileName;
  }

  transformAndExportAsExcelFile(rows: any[]) {
    const exportableData = rows.map((row: any) => {
      const rowCasted = this.schema.cast(row);
      return this._makeNameHeadersIntoLabels(rowCasted);
    });

    return Excel.exportAsExcelFile(
      exportableData,
      this.schema.labels,
      this.excelFileName + '_' + new Date().getTime(),
    );
  }

  _makeNameHeadersIntoLabels(row: any) {
    return mapKeys(row, (value, key) => {
      return this.schema.labelOf(key);
    });
  }
}
