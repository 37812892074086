import { i18n, i18nExists } from '@/i18n';
import { AuthService } from '@/services/auth-service';

const DEFAULT_ERROR_MESSAGE = i18n(
  'errors.defaultErrorMessage',
);

function selectErrorKeyOrMessage(error:any) {
  if (error && error.response && error.response.data) {
    const data = error.response.data;
    if (data.error && data.error.message) {
      return data.error.message;
    }
    return String(data);
  }
  return error.message || DEFAULT_ERROR_MESSAGE;
}

function selectErrorMessage(error:any) {
  const key = selectErrorKeyOrMessage(error);
  if (i18nExists(key)) {
    return i18n(key);
  }
  return key;
}

function selectErrorCode(error:any) {
  if (error && error.response && error.response.status) {
    return error.response.status;
  }
  return 500;
}

export default class Errors {
  
  static handle(error:any):string {
    if (process.env.NODE_ENV !== 'localhost') {
      console.error(selectErrorMessage(error));
      console.error(error);
    }

    if (selectErrorCode(error) === 401) {
      AuthService.signout();
      window.location.reload();
      return i18n('errors.401');
    }

    if (selectErrorCode(error) === 408) {
      return i18n('errors.408');
    }

    if (selectErrorCode(error) === 403) {
      return i18n('errors.403');
    }

    if ([400, 429].includes(selectErrorCode(error))) {
      return selectErrorMessage(error);
    }

    return i18n('errors.500');
  }

  static errorCode(error:any) {
    return selectErrorCode(error);
  }

  static selectMessage(error:any) {
    return selectErrorMessage(error);
  }


}
