

import { defineComponent, ref, PropType } from "vue";
import {
  Peripheral,
} from "@/models/arniaperfetta";

import { PeripheralService } from "@/services/peripheral-service";
import { Utils } from "@/shared/utils";
import { I18nUtil } from "@/shared/i18n/i18n-util";

export default defineComponent({
  name: "peripheral-icon",

  props: {
    item: {
      type: Object as PropType<Peripheral>,
      required: true,
    },
    is_hub: {
      type: Boolean,
    },
  },

  components: {},
  computed: {
    peripheral_icon() {
      return Utils.peripheral_icon(this.item.type, this.is_hub);
    },
  },
  methods: {
  },
  setup(props) {

    return {};
  },
});
