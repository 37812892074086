

import { v4 as uuid } from 'uuid';
export interface Peripheral {
    id: number;
    parent: number | undefined;
    imei: string;
    name: string;
    hub_name: string;
    type_name: string;
    timestamp: Date;
    type: PeripheralType;
    status: AlarmStatus;
    connection: ConnectionStatus;
    position: Position | undefined;
    alarms: Alarm[];
    metrics: Metrics;
    thresholds: Thresholds;
    alarm_config: AlarmsConfig;
    monitoring: Monitoring;
    idau: number;
}


export class Hub implements Peripheral {
    constructor(id: number) {
        this.id = id;
        this.name = id.toString();
        this.hub_name = "";
        this.imei = "";
        this.alarm_config = {};
        this.monitoring = {};
    }
    id: number;
    parent: number | undefined;
    position: Position | undefined;
    name: string;
    hub_name: string;
    type_name: string = '';
    imei: string;
    timestamp!: Date;
    queen_marker!: QueenMarker;
    type: PeripheralType = PeripheralType.BHUB;
    status!: AlarmStatus;
    connection!: ConnectionStatus;
    alarms: Alarm[] = [];
    metrics: Metrics = new Metrics;
    thresholds: Thresholds = new Thresholds;
    alarm_config: AlarmsConfig;
    monitoring: Monitoring;
    idau!: number;
}

export class Sentinel implements Peripheral {
    constructor(id: number) {
        this.id = id;
        this.name = id.toString();
        this.hub_name = "";
        this.imei = "";
        this.alarm_config = {};
        this.monitoring = {};
    }
    id: number;
    parent: number | undefined;
    position: Position | undefined;
    name: string;
    hub_name: string;
    type_name: string = '';
    imei: string;
    timestamp!: Date;
    queen_marker!: QueenMarker;
    type: PeripheralType = PeripheralType.BSENTINEL;
    status!: AlarmStatus;
    connection!: ConnectionStatus;
    alarms: Alarm[] = [];
    metrics: Metrics = new Metrics;
    thresholds: Thresholds = new Thresholds;
    alarm_config: AlarmsConfig;
    monitoring: Monitoring;
    idau!: number;
}


export class Meteo implements Peripheral {
    constructor(id: number) {
        this.id = id;
        this.name = id.toString();
        this.hub_name = "";
        this.imei = "";
        this.alarm_config = {};
        this.monitoring = {};
    }
    id: number;
    parent: number | undefined;
    position: Position | undefined;
    name: string;
    hub_name: string;
    type_name: string = '';
    imei: string;
    timestamp!: Date;
    type: PeripheralType = PeripheralType.BMETEO;
    status!: AlarmStatus;
    connection!: ConnectionStatus;
    alarms: Alarm[] = [];
    metrics: Metrics = new Metrics;
    thresholds: Thresholds = new Thresholds;
    alarm_config: AlarmsConfig;
    monitoring: Monitoring;
    idau!: number;
}

export class Scale implements Peripheral {
    constructor(id: number) {
        this.id = id;
        this.name = id.toString();
        this.hub_name = "";
        this.imei = "";
        this.alarm_config = {};
        this.monitoring = {};
        this.tare = { gross_weight: 0, weight: 0, hives:0, last_hour:0, diff:0 }
    }
    id: number;
    parent: number | undefined;
    position: Position | undefined;
    name: string;
    hub_name: string;
    type_name: string = '';
    imei: string;
    timestamp!: Date;
    type: PeripheralType = PeripheralType.BSCALE;
    status!: AlarmStatus;
    connection!: ConnectionStatus;
    alarms: Alarm[] = [];
    metrics: Metrics = new Metrics;
    thresholds: Thresholds = new Thresholds;
    alarm_config: AlarmsConfig;
    tare: WeightTare;
    monitoring: Monitoring;
    idau!: number;
}


export class Alarm {
    constructor(type: AlarmType, value: number) {
        this.type = type;
        this.value = value;
        this.disabled = value === 255;
        this.visible = value != 254;
        this.reset = false;
        this.icon = AlarmType[type].toLowerCase()
    }
    type!: AlarmType;
    status!: AlarmStatus;
    value!: number;
    icon!: string;
    color!: string;
    disabled!: boolean;
    visible!: boolean;
    reset!: boolean;
}


export enum ConnectionStatus {
    ONLINE = 1,
    OFFLINE,
}


export enum AlarmStatus {
    ALARMED = 2,
    ALERTED = 1,
    NORMAL = 0,
}

export enum PeripheralType {
    BHUB = 1,
    BSENTINEL = 2,
    BMETEO = 3,
    BSCALE = 4,
}

export enum AlarmType {
    TILT = 1,
    MOTION,
    TRACKING,
    BATTERY,
    BATTERY_SIBLING,
    HUMIDITY,
    TEMPERATURE,
    WEIGHT,
    NUTRITION,
    SUPER_ADD,
    HONEY_EXTRACTION,
    SWARMING,
    TREATMENT,
    HEALTH,
    SUNLIGHT,
    WIND,
    RAIN,
    CONNECTED,
    NOTE,
    ALARM,
    CONNECTION
}



export class Thresholds {
    temperature: RangeValue | undefined;
    humidity: RangeValue | undefined;
    weight: RangeValue | undefined;
}

export class Metrics {
    battery: number | undefined;
    temperature: number | undefined;
    temperature_min: number | undefined;
    temperature_max: number | undefined;
    humidity: number | undefined;
    humidity_min: number | undefined;
    humidity_max: number | undefined;
    weight: number | undefined;
    weight_diff: number | undefined;
    weight_daily: WeightDaily | undefined;
}

export interface AlarmsConfig extends Record<string, any> {
    motion?: boolean;
    motion_vacation?: Date;
    tilt?: boolean;
    tilt_vacation?: Date;
    tracking?: boolean;
    tracking_vacation?: Date;
    heat?: boolean;
    heat_vacation?: Date;
}

export interface WeightDaily extends Record<string, any> {
    latest?: Date;
    net_weight?: number;
    weight?: number;
    day_before?: Date;
    weight_day_before?: number;
    net_weight_day_before?: number;
    weight_diff?: number;
    hours?: WeightHour[];
}

export interface WeightHour extends Record<string, any> {
    key?: number;
    value?: number;
    diff?: number;
}

export interface WeightTare extends Record<string, any> {
    gross_weight: number;
    weight: number;
    diff: number;
    date?: Date;
    last_hour: number;
    hives: number;
}

export interface Monitoring extends Record<string, any> {
    level?: number;
    activation?: Date;
    expire?: Date;
    expired?: boolean;
}


export class Position {
    constructor(lat: number, lng: number) {
        this.lat = lat;
        this.lng = lng;
    }
    lat: number;
    lng: number;
}

export class Badge {
    constructor(key: string) {
        this.key = key;
    }
    key: string;
    color: string | undefined;
    icon: string | undefined;
    label: string | undefined;
}

// Azzurro= 0, Bianco=1, Giallo=2, Rosso=3, Verde=4 (fucaiola Viola=5, orfana Nera=6
export enum QueenMarker {
    BLUE = 1,
    WHITE = 2,
    YELLOW = 3,
    RED = 4,
    GREEN = 5,
    PURPLE = 6,
    BLACK = 7
}


export interface GlobalConfiguration extends Record<string, any> {
    e_mail?: string;
    sms_phone?: string;
    sms_enable?: boolean;
    language?: string;
    // https://stackoverflow.com/questions/44115681/javascript-check-if-timezone-name-valid-or-not
    timezone?: string;
    monitoring_available?: number;
    top_card?: boolean;
    top_card_until?: Date;
    top_card_expired?: boolean;
    extra_e_mail1?: string;
    extra_e_mail2?: string;
    extra_e_mail3?: string;


    alarm_global_disable?: boolean;
    alarm_hub_sentinel_disable?: boolean;
    alarm_meteo_disable?: boolean;
    alarm_scale_disable?: boolean;

}

export class GlobalConfigurationAlarms {
    constructor() {
        this.hub_alarmed = true;
        this.sentinel_alarmed = true;
        this.meteo_alarmed = true;
        this.scale_alarmed = true;
    }
    hub_alarmed!: boolean;
    sentinel_alarmed!: boolean;
    meteo_alarmed!: boolean;
    scale_alarmed!: boolean;
}


export class Note {
    constructor() {
        this.id = uuid();
    }
    id: string;
    title!: string;
    description!: string;
    timestamp!: Date;
    idau!: number;
    source!: number;
    tags!: string[];
}

export type RangeValue = number | { lower: number, upper: number };