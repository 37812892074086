import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container rcorners"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_ctx.color)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ion_icon, {
          src: "assets/mdt-italia/000_bee.svg",
          class: "custom_icon",
          color: _ctx.color
        }, null, 8, ["color"])
      ]))
    : _createCommentVNode("", true)
}