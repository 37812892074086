

import authAxios from '@/shared/axios/auth-axios';
import _isNil from 'lodash/isNil';

import { Peripheral, PeripheralType, Scale } from '@/models/arniaperfetta';
import { i18n } from '@/i18n';
import DateTimeField from '@/shared/fields/date-time-field';
import StringField from '@/shared/fields/string-field';
import DecimalField from '@/shared/fields/decimal-field';
import IntegerField from '@/shared/fields/integer-field';
import moment from "moment";


function label(name: string) {
  return i18n(`export.fields.${name}`);
}

function find_y_value(array: any[], row: any) {
  let y_value = null;
  try {
    y_value = array.find((r: { x: any; }) => r.x === row.x).y
  } catch (error) {

  }

  return y_value;
}

export class MetricsService {

  static sources(peripheral: Peripheral, meteo: Peripheral | undefined) {
    const sources: any = [];
    switch (peripheral.type) {
      case PeripheralType.BSENTINEL:
      case PeripheralType.BHUB:
        sources.push({
          id: peripheral.id,
          metric: "temperature",
          name: "temperature",
        });
        sources.push({
          id: peripheral.id,
          metric: "humidity",
          name: "humidity",
        });

        if (meteo) {
          sources.push({
            id: meteo.id,
            metric: "temperature",
            name: "temperature_ext",
          });
          sources.push({
            id: meteo.id,
            metric: "humidity",
            name: "humidity_ext",
          });
        }

        break;
      case PeripheralType.BMETEO:
        sources.push({
          id: peripheral.id,
          metric: "temperature",
          name: "temperature_ext",
        });
        sources.push({
          id: peripheral.id,
          metric: "humidity",
          name: "humidity_ext",
        });

        break;
      case PeripheralType.BSCALE:
        sources.push({ id: peripheral.id, metric: "weight", name: "weight" });
        break;
      default:
        break;
    }

    return sources;
  }

  static export(peripheral: Peripheral | undefined, datasets: any, timeframe: any) {

    const hive_fields = [
      new DateTimeField(
        'timestamp',
        label('timestamp'),
      ),
      new DecimalField(
        'temperature',
        label('temperature'),
        { scale: 2 }
      ),

      new DecimalField(
        'temperature_ext',
        label('temperature_ext'),
        { scale: 2 }
      ),


      new IntegerField(
        'humidity',
        label('humidity'),
      ),


      new IntegerField(
        'humidity_ext',
        label('humidity_ext'),
      ),

    ]

    const meteo_fields = [
      new DateTimeField(
        'timestamp',
        label('timestamp'),
      ),
      new DecimalField(
        'temperature_ext',
        label('temperature_ext'),
        { scale: 2 }
      ),

      new IntegerField(
        'humidity_ext',
        label('humidity_ext'),
      ),
    ]

    const scale_fields = [
      new DateTimeField(
        'timestamp',
        label('timestamp'),
      ),
      new DecimalField(
        'wmetric1',
        label('wmetric1'),
        { scale: 2 }
      ),
      new DecimalField(
        'wmetric2',
        label('wmetric2'),
        { scale: 2 }
      ),
      new DecimalField(
        'wmetric3',
        label('wmetric3'),
        { scale: 2 }
      ),
    ]

    const scale_fields_days = [
      new DateTimeField(
        'timestamp',
        label('timestamp'),
      ),
      new DecimalField(
        'weight',
        label('weight'),
        { scale: 2 }
      ),
    ]

    const temperature = datasets['temperature'] || [];
    const humidity = datasets['humidity'] || [];
    const temperature_ext = datasets['temperature_ext'] || [];
    const humidity_ext = datasets['humidity_ext'] || [];
    const weight = datasets['weight'] || [];
    const wmetric1 = datasets['wmetric1'] || [];
    const wmetric2 = datasets['wmetric2'] || [];
    const wmetric3 = datasets['wmetric3'] || [];

    let fields: any[] = [];
    const rows: any[] = [];
    if (!peripheral || !datasets) {
      return { fields, rows };
    }


    switch (peripheral.type) {
      case PeripheralType.BSENTINEL:
      case PeripheralType.BHUB:
        fields = hive_fields
        for (const row of temperature) {
          try {
            rows.push({
              timestamp: row.x,
              temperature: row.y,
              humidity: find_y_value(humidity, row),
              temperature_ext: find_y_value(temperature_ext, row),
              humidity_ext: find_y_value(humidity_ext, row),
            });
          } catch (error) {

          }

        }

        break;
      case PeripheralType.BMETEO:
        fields = meteo_fields
        for (const row of temperature_ext) {
          try {
            rows.push({
              timestamp: row.x,
              temperature_ext: row.y,
              humidity_ext: find_y_value(humidity_ext, row),
            });
          } catch (error) {

          }

        }
        break;
      case PeripheralType.BSCALE:

        if (timeframe === 'days') {
          fields = scale_fields_days
          for (const row of weight) {
            try {
              rows.push({
                timestamp: row.x,
                weight: row.y,
              });
            } catch (error) {
            }
          }
        }
        else {
          fields = scale_fields
          for (const row of wmetric1) {
            try {
              rows.push({
                timestamp: row.x,
                wmetric1: row.y,
                wmetric2: find_y_value(wmetric2, row),
                wmetric3: find_y_value(wmetric3, row),
              });
            } catch (error) {
            }
          }
        }
        break;
      default:
        break;
    }

    return { fields, rows };

  }

  static calculate_weight_metrics(metric: any[], item: Scale, range: any[]) {
    
    const wmetric1: any[] = [];
    const wmetric2: any[] = [];
    const wmetric3: any[] = [];

    const tare_weight = item.tare.weight || 0;
    const hives = item.tare.hives || 1;

    const unique = [...new Set(metric.map(el => el.a))].sort();
    // unique dates YYYY-MM-DD
    let dw_before = undefined
    // { x, y, h, lh, a, ts, dw, df } 
    // x 'YYYY-MM-DD HH:mm'
    // a 'YYYY-MM-DD' 
    // lh last hour
    // dw day weight
    // df diff
    for (const day of unique) {
      const ts = moment(day).utc().startOf('day').valueOf();
      // const m05 = metric.find(m => m.a === day && m.h === 5);
      // const m12 = metric.find(m => m.a === day && m.h === 12);
      const mdw = metric.find(m => m.a === day && m.lh === true);
      if (!_isNil(mdw)) {
        // Andamento del Peso da TARA Bilancia / Peso netto
        wmetric1.push({ x: day, y: mdw.dw - tare_weight, ts });
        wmetric2.push({ x: day, y: mdw.df, ts });       
        wmetric3.push({ x: day, y: mdw.dw, ts });
        // Variazione del Peso Medio Stimato x Arnia
        // wmetric2.push({ x: day, y: ( mdw.y - tare_weight) / hives, ts });
      }
      // if (!_isNil(m05) && !_isNil(m12)) {
      //   let y = Math.trunc((m12.y - m05.y) * (-10000) / hives);
      //   if (y < 0) {
      //     y = 0;
      //   }
      //   // Volo Api - Stimato x Arnia
      //   wmetric3.push({ x: day, y, ts });
      // }
    }

    const wmetric_net = metric.map((obj, i) => ({ ...obj, y: obj.y - tare_weight  }));

    return { wmetric1, wmetric2, wmetric3, wmetric_net };
  }

  static async list(filter: any, orderBy: any, limit: any, offset: any) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };
    const response = await authAxios.get(
      `/metrics`,
      {
        params,
      },
    );
    return response.data;
  }





}
