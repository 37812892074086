import authAxios from '@/shared/axios/auth-axios';

export class NoteService {
  static async update(id: any, data: any) {
    const body = {
      id,
      data,
    };
    const response = await authAxios.put(
      `/note/${id}`,
      body,
    );
    return response.data;
  }
  static async destroyAll(ids: any) {
    const params = {
      ids,
    };
    const response = await authAxios.delete(
      `/note`,
      {
        params,
      },
    );
    return response.data;
  }

  static async create(data: any) {
    const body = {
      data,
    };
    const response = await authAxios.post(
      `/note`,
      body,
    );
    return response.data;
  }


  static async find(id: any) {
    const response = await authAxios.get(
      `/note/${id}`,
    );
    return response.data;
  }

  static async list(filter: any, orderBy: any, limit: any, offset: any) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };
    const response = await authAxios.get(
      `/note`,
      {
        params,
      },
    );
    return response.data;
  }


}
