export default class GenericField {
  name: string;
  label: string;

  constructor(name: string, label: string) {
    this.name = name;
    this.label = label;
  }

  forPresenter(value: any) {
    return value;
  }

  forExport(): any {
    return undefined;
  }

  forImport(): any {
    return undefined;
  }
}
