import * as yup from 'yup';

export default class ExporterSchema {
  fields;
  yupSchema;

  constructor(fields: any) {
    this.fields = fields;
    this.yupSchema = this.buildSchema();
  }

  get labels() {
    return this.fields.map((field: { label: any; }) => field.label);
  }

  labelOf(name: string) {
    const field = this.fields.find(
      (field: { name: any; }) => field.name === name,
    );

    if (field) {
      return field.label;
    }

    return name;
  }

  buildSchema() {
    const shape = {};

    this.fields.forEach((field: { name: string | number; forExport: () => any; }) => {
      // @ts-ignore
      shape[field.name] = field.forExport();
    });

    return yup
      .object()
      .shape(shape)
      .noUnknown(true);
  }

  cast(row: any) {
    return this.yupSchema.cast(row);
  }
}
