
import { defineComponent, ref, PropType } from "vue";
import { Peripheral } from "@/models/arniaperfetta";
import { PeripheralService } from "@/services/peripheral-service";
import { Utils } from "@/shared/utils";
import { I18nUtil } from "@/shared/i18n/i18n-util";

export default defineComponent({
  name: "peripheral-connection",
  props: {
    item: {
      type: Object as PropType<Peripheral>,
      required: true,
    },
    hub_name: {
      type :Boolean,
      default: false
    }
  },

  components: {},
  computed: {
    connection_icon() {
      return Utils.connection_icon(this.item);
    },
    connection_color() {
      return Utils.connection_color(this.item);
    },
    peripheral_name() {
      return PeripheralService.peripheral_name(this.item, this.hub_name);
    }
  },
  methods: {
    formatDateTime(value: any) {
      return I18nUtil.formatDateTime(value);
    },
  },
  setup(props) {
    return {};
  },
});
