
import { defineComponent, ref, PropType } from "vue";
import { Note } from "@/models/arniaperfetta";
import { I18nUtil } from "@/shared/i18n/i18n-util";

export default defineComponent({
  name: "note-item",

  props: {
    item: {
      type: Object as PropType<Note>,
      required: true,
    },
  },

  components: {},
  computed: {},
  methods: {
    formatDateTime(value: any) {
      return I18nUtil.formatDateTime(value);
    },
    renderTags(value: any) {
      return I18nUtil.tags_render(this.item.tags);
    },
    details() {
      this.$emit("details", this.item);
    },
  },
  setup() {
    return {};
  },
});
