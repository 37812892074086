
import { IonApp, IonRouterOutlet, IonProgressBar } from "@ionic/vue";
import {
  defineComponent,
  onBeforeMount,
  watch,
  onMounted,
  onUnmounted,
  inject,
} from "vue";
import { useBackButton, useIonRouter } from "@ionic/vue";
import { AuthToken } from "@/shared/auth/auth-token";

import { useStore, MutationTypes, ActionTypes } from "@/store";
import {
  alertController,
  modalController,
  loadingController,
  toastController,
} from "@ionic/vue";
import Errors from "@/shared/error/errors";
import { i18n } from "@/i18n";
import { AuthService } from "./services/auth-service";
import config from "@/config";

import { useOneSignal } from "@onesignal/onesignal-vue3";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonProgressBar,
  },
  // sockets: {
  //   connect: function () {
  //     console.log('socket to notification channel connected')
  //   },
  //   disconnect: function () {
  //     console.log('socket to notification channel disconnected')
  //   },
  // },
  setup() {
    const store = useStore();
    const onesignal: any = useOneSignal();

    if (onesignal) {
      try {
        onesignal.on("notificationDisplay", (obj: any) => {
          console.log("notificationDisplay");
          console.log(obj);
          store.dispatch(ActionTypes.doInit);
        });
      } catch (error) {
        console.log(error);
      }
    }

    onBeforeMount(async () => {
      store.dispatch(ActionTypes.doAuthInit);
    });

    onMounted(async () => {
      onesignal.showSlidedownPrompt();
    });

    const handleResize = () => {
      store.dispatch(ActionTypes.doResize, {
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    onMounted(async () => {
      handleResize();
    });


    watch(
      () => store.state.currentUser,
      async () => {
        if (store.state.currentUser) {
          if (onesignal) {
            store.dispatch(ActionTypes.doWebpushSubscription, {onesignal});
          }
        }
      }
    );

    watch(
      () => store.state.error,
      async () => {
        //console.log(store.state.error);
        if (store.state.error) {
          let message = Errors.handle(store.state.error);
          if (!store.state.connected) {
            message = i18n("errors.408");
          }
          const toast = await toastController.create({
            position: "middle",
            color: "danger",
            header: i18n("errors.header"),
            message,
            duration: 3000,
          });
          await toast.present();

          //store.commit(MutationTypes.setError, null);
          // const loggedIn = !!AuthToken.get();
          // if (!loggedIn) {
          //   store.dispatch(ActionTypes.doSignout);
          // }
        }
      }
    );
    return {
      store,
      onesignal,
    };
  },
  methods: {},
});
