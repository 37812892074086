// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat

import _get from 'lodash/get';
import moment from 'moment';

import { version } from '../../package.json';
import itJson from './it.json';
import enJson from './en.json';
import deJson from './de.json';
import frJson from './fr.json';
import esJson from './es.json';


let currentLanguageCode = '';
let currentTimeZone = '';

const languages: {
  [key: string]: {
    id: string;
    label: string;
    flag: string;
    dictionary: any;
  };
} = {
  it: {
    id: 'it',
    label: 'Italiano',
    flag: '/assets/flags/Italy.png',
    dictionary: itJson,
  },
  en: {
    id: 'en',
    label: 'English',
    flag: '/assets/flags/United-Kingdom.png',
    dictionary: enJson,
  },
  de: {
    id: 'de',
    label: 'German',
    flag: '/assets/flags/Germany.png',
    dictionary: deJson,
  },
  fr: {
    id: 'fr',
    label: 'France',
    flag: '/assets/flags/France.png',
    dictionary: frJson,
  },
  es: {
    id: 'es',
    label: 'Spain',
    flag: '/assets/flags/Spain.png',
    dictionary: esJson,
  },
};

export function init() {
  currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let userLang = 'en'
  Object.keys(languages).forEach(lang => {
    if (navigator.language.includes(lang)) {
      userLang = lang
    }
  })
  //console.log(userLang)
  currentLanguageCode = localStorage.getItem('language') || userLang;
  moment.locale(currentLanguageCode);
  setLanguageCode(currentLanguageCode);
}


// export async function initAsync() {
//   currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//   currentLanguageCode = localStorage.getItem('language') || 'it';
//   setLanguageCode(currentLanguageCode);
//   if (currentLanguageCode === 'it') {
//     await initIt();
//   }
// }

// async function initIt() {
//   const language = languages['it'];
//   language.dictionary = (await import('@/i18n/it.json'));
//   return language;
// }

function getLanguage() {
  return languages[getLanguageCode()];
}

function format(message: string, args: any[]) {
  if (!message) {
    return null;
  }

  try {
    return message.replace(/{(\d+)}/g, function (
      match: any,
      number: string | number,
    ) {
      // @ts-ignore
      return typeof args[number] != 'undefined' ? args[number] : match;
    });
  } catch (error) {
    console.error(message, error);
    throw error;
  }
}

export function getLanguages() {
  return Object.keys(languages).map((language) => {
    return languages[language];
  });
}

export function getLocaleCode() {
  if (currentLanguageCode === 'en') {
    return 'en-GB';
  }
  return `${currentLanguageCode}-${currentLanguageCode.toUpperCase()}` ;
}


// Get the time zone set on the user's device
export function getTimeZone() {
  return currentTimeZone;
}

export function getLanguageCode() {
  return currentLanguageCode;
}

export function setLanguageCode(arg: any) {
  if (!languages[arg]) {
    throw new Error(`Invalid language ${arg}.`);
  }
  localStorage.setItem('language', arg);
}

export function i18nExists(key: any) {
  if (!getLanguage()) {
    return false;
  }
  const message = _get(getLanguage().dictionary, key);
  return Boolean(message);
}

export function i18n(key: any, ...args: any[]) {
  if (key === 'version') {
    return version
  }
  if (!getLanguage()) {
    return key;
  }
  const message = _get(getLanguage().dictionary, key);
  if (!message) {
    return key;
  }
  return format(message, args);
}
