

import { defineComponent, ref, PropType } from "vue";
import {
  Peripheral,
} from "@/models/arniaperfetta";

import { PeripheralService } from "@/services/peripheral-service";

export default defineComponent({
  name: "queen-marker-icon",
  props:['color'],
  components: {},
  computed: {

  },
  methods: {
  },
  setup(props) {
    return {};
  },
});
