
import { useStore, MutationTypes, ActionTypes } from "@/store";
import { defineComponent, computed, PropType, toRefs } from "vue";
import { Peripheral, PeripheralType, Scale } from "@/models/arniaperfetta";
import ScaleTareModal from "@/components/ScaleTareModal.vue";
import { modalController, alertController } from "@ionic/vue";
import { i18n } from "@/i18n";
import moment from "moment";
import { PeripheralService } from "@/services/peripheral-service";

export default defineComponent({
  name: "scale-tare-item",

  props: {
    item: {
      type: Object as PropType<Scale>,
      required: true,
    },
  },

  components: {},
  computed: {},
  methods: {
    i18n(code: any) {
      return i18n(code);
    },

    formatDate(value: any,format: string = 'YYYY-MM-DD') {
      if (value) {
        return moment(value).format(format);
      }
      return undefined;
    },

    async editTare() {
      const modal = await modalController.create({
        component: ScaleTareModal,
        cssClass: "alert-small-class",
        componentProps: { item: this.scale },
      });
      modal.onDidDismiss().then((resp) => {});
      return modal.present();
    },
  },
  setup(props) {
    const { item } = toRefs(props);
    const key = item.value.id;
    const store = useStore();
    const scale = computed(() => store.state.peripherals.find((el) => el.id === key));

    return {
      store,
      scale,
    };
  },
});
