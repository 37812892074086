
import { i18n, getLocaleCode } from "@/i18n";
import { defineComponent, ref, onUnmounted, inject } from "vue";
import { modalController } from "@ionic/vue";
import { alarm, closeCircle } from "ionicons/icons";

export default defineComponent({
  props: ["min", "max", "presentation", "value", "title"],
  name: "alarm-datetime-modal",
  components: {},
  methods: {
    i18n(code: any) {
      return i18n(code);
    },
    async changed($event: any) {
      await modalController.dismiss({ datetime: $event.detail.value });
    },
    async dismiss() {
      await modalController.dismiss();
    },
  },
  setup() {
    const bus: any = inject("emitter");
    const dismiss = async function () {
      const modal = await modalController.getTop();
      if (modal) {
        await modal.dismiss();
      }
    };
    bus.on("route-changed", dismiss);
    onUnmounted(() => {
      bus.off("route-changed", dismiss);
    });
    const locale = getLocaleCode();
    const customDatetime = ref();
    return { closeCircle, customDatetime, locale };
  },
});
