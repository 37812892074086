// Place the URL here with the /api suffix.
// Ex.:`https://domain.com/api`;
const backendUrl = `/api`;


const onesignal_appId = "670344d4-0e36-4f65-9395-0f16ea71352d";
const google_key = "AIzaSyDPD7_LXffluz5Kiakbz1RN1OR6Sd7ijxs"

/**
 * Frontend URL.
 */
const frontendUrl = {
  host: 'arniaperfetta.app',
  protocol: 'https',
};


export default {
  frontendUrl,
  backendUrl,
  onesignal_appId,
  google_key,
}

