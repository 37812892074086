
/* eslint-disable */

import { Peripheral,PeripheralType } from "@/models/arniaperfetta";
import { modalController } from "@ionic/vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { closeCircle, save } from "ionicons/icons";
import {
  defineComponent,
  ref,
  PropType,
  toRefs,
  reactive,
  computed,
  onUnmounted,
  inject,
} from "vue";
import { i18n } from "@/i18n";
import { useStore, MutationTypes, ActionTypes } from "@/store";
import { Utils } from "@/shared/utils";


type FormDataType = {
  name?: string | undefined;
};

export default defineComponent({
  name: "name-modal",
  props: {
    item: {
      type: Object as PropType<Peripheral>,
      required: true,
    },
    is_hub: {
      type: Boolean,
      required: true,
    },
  },

  components: { Form, Field, ErrorMessage },
  computed: {},
  methods: {
    async dismiss() {
      await modalController.dismiss();
    },
    i18n(code: any) {
      return i18n(code);
    },
  },

  setup(props) {
    const bus: any = inject("emitter");
    const dismiss = async function () {
      const modal = await modalController.getTop();
      if (modal) {
        await modal.dismiss();
      }
    };
    bus.on("route-changed", dismiss);
    onUnmounted(() => {
      bus.off("route-changed", dismiss);
    });
    const { item, is_hub } = toRefs(props);
    const title = is_hub.value
      ? i18n("common_edit_hub_name_title")
      : i18n("common_edit_name_title");
    const store = useStore();
    const progress = computed(() => store.state.progress);
    const peripheral_icon = Utils.peripheral_icon(
      item.value.type,
      is_hub.value
    );

    let label = is_hub.value
      ? i18n("common_peripheral_hub_name")
      : i18n("common_peripheral_name");

    
    label = `${item.value.id} - ${item.value.type_name}`;
    const name = is_hub.value ? item.value.hub_name : item.value.name;
    const formData = ref<FormDataType>({
      name,
    });
    const methods = reactive({
      async onSubmit(data: FormDataType) {
        const value: Peripheral = item.value as Peripheral;
        const payload: any = {};
        if (is_hub.value) {
          item.value.hub_name = data.name!;
          payload["hub_name"] = data.name!;
        } else {
          item.value.name = data.name!;
          payload["name"] = data.name!;
        }

        await store.dispatch(ActionTypes.doUpdatePeripheralName, {
          item: value,
          payload,
        });
        if (is_hub.value) {
          await store.dispatch(ActionTypes.doInit);
        }

        if (!store.state.error) {
          await modalController.dismiss();
        }
      },
    });

    return {
      ...toRefs(methods),
      progress,
      formData,
      title,
      label,
      peripheral_icon,
      closeCircle,
      save,
    };
  },
});
