import { Component, createApp } from 'vue'
import App from './App.vue'
import router from '@/router';
import { store, key } from "@/store";
import config from '@/config';

import {
  defineRule,
  configure
} from 'vee-validate'

import {
  required,
  email,
  confirmed,
  alpha_num,
  min
} from '@vee-validate/rules'

import { localize } from '@vee-validate/i18n'

defineRule('required', required)
defineRule('email', email)
defineRule('confirmed', confirmed)
defineRule('min', min)
defineRule('alpha_num', alpha_num)



import {
  i18n,
  init as i18nInit
} from '@/i18n';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import '@/theme/variables.scss';

import VueGoogleMaps from '@fawmi/vue-google-maps'
//import Chart from 'chart.js';


import {
  IonNote,
  IonFooter,
  IonItemDivider,
  IonItemGroup,
  IonCardSubtitle,
  IonLoading,
  IonImg,
  IonProgressBar,
  IonThumbnail,
  IonSkeletonText,
  IonPicker,
  IonSelect,
  IonSelectOption,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonRange,
  IonRadio,
  IonRadioGroup,
  IonFab,
  IonFabButton,
  IonDatetime,
  IonText,
  IonTextarea,
  IonInput,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonChip,
  IonPage,
  IonContent,
  IonPopover,
  IonAvatar,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonButton,
  IonButtons,
  IonToggle,
  IonList,
  IonSlide,
  IonSlides,
  IonIcon,
  IonTabBar,
  IonTabButton,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonListHeader,
  IonTabs,
  IonicVue
} from "@ionic/vue";


const app = createApp(App)
  .use(store, key)
  .use(IonicVue)
  .use(router);


import $bus from "@/shared/event"
app.config.globalProperties.emitter = $bus;
app.provide('emitter', app.config.globalProperties.emitter)


// https://medium.com/daily-now/using-onesignal-in-a-vue-cli-3-application-d06c9fb40583
// The only problem remains is the conflict between 
// the existing service worker of the PWA module of Vue CLI 
// and OneSignal’s service worker for web push.

//import OneSignalVue from "@/onesignal"

import OneSignalVuePlugin from '@onesignal/onesignal-vue3'

if (config.onesignal_appId) {
  const appId = config.onesignal_appId;
  const subdomainName = config.onesignal_subdomainName;
  // The OneSignal plugin automatically exposes a $OneSignal global property accessible inside the application.
  app.use(OneSignalVuePlugin, {
    appId,
    subdomainName,
    safari_web_id: "",
    notifyButton: {
      enable: true,
    },
    // serviceWorkerParam: { scope: "/onesignal/" },
    // serviceWorkerPath: 'onesignal/OneSignalSDKWorker.js'
  })
}

import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
//Chart.defaults.font.family = "Poppins-Regular";
import 'chartjs-adapter-moment'


app.component('ion-note', IonNote);
app.component('ion-footer', IonFooter);
app.component('ion-item-divider', IonItemDivider);
app.component('ion-item-group', IonItemGroup);
app.component('ion-card-subtitle', IonCardSubtitle);

app.component('ion-loading', IonLoading);
app.component('ion-img', IonImg);
app.component('ion-progress-bar', IonProgressBar);
app.component('ion-thumbnail', IonThumbnail);
app.component('ion-skeleton-text', IonSkeletonText);
app.component('ion-picker', IonPicker);
app.component('ion-select-option', IonSelectOption);
app.component('ion-select', IonSelect);

app.component('ion-card', IonCard);
app.component('ion-card-header', IonCardHeader);
app.component('ion-card-title', IonCardTitle);
app.component('ion-card-content', IonCardContent);

app.component('ion-range', IonRange);
app.component('ion-radio', IonRadio);
app.component('ion-radio-group', IonRadioGroup);
app.component('ion-fab', IonFab);
app.component('ion-fab-button', IonFabButton);
app.component('ion-datetime', IonDatetime);
app.component('ion-input', IonInput);
app.component('ion-title', IonTitle);
app.component('ion-toolbar', IonToolbar);
app.component('ion-header', IonHeader);
app.component('ion-list-header', IonListHeader);
app.component('ion-item-option', IonItemOption);
app.component('ion-item-options', IonItemOptions);
app.component('ion-item-sliding', IonItemSliding);
app.component('ion-tab-bar', IonTabBar);
app.component('ion-tab-button', IonTabButton);
app.component('ion-chip', IonChip);
app.component('ion-page', IonPage);
app.component('ion-content', IonContent);
app.component('ion-popover', IonPopover);
app.component('ion-avatar', IonAvatar);
app.component('ion-grid', IonGrid);
app.component('ion-row', IonRow);
app.component('ion-col', IonCol);
app.component('ion-item', IonItem);
app.component('ion-label', IonLabel);
app.component('ion-button', IonButton);
app.component('ion-toggle', IonToggle);
app.component('ion-list', IonList);
app.component('ion-buttons', IonButtons);
app.component('ion-slide', IonSlide);
app.component('ion-slides', IonSlides);
app.component('ion-icon', IonIcon);
app.component('ion-text', IonText);
app.component('ion-textarea', IonTextarea);

app.component('ion-tabs', IonTabs);


import MasterLayout from '@/components/MasterLayout.vue';
import AlarmDatetimeModal from '@/components/DatetimeModal.vue';
import HubItem from '@/components/HubItem.vue';
import NoteItem from '@/components/NoteItem.vue';
import PeripheralItem from '@/components/PeripheralItem.vue';
import PeripheralConnection from '@/components/PeripheralConnection.vue';
import PeripheralChips from '@/components/PeripheralChips.vue';
import PeripheralIcon from '@/components/PeripheralIcon.vue';
import PeripheralMetrics from '@/components/PeripheralMetrics.vue';
import QueenMarkerIcon from '@/components/QueenMarkerIcon.vue';
import ScaleTareItem from '@/components/ScaleTareItem.vue';
import SkeletonText from '@/components/SkeletonText.vue';
import I18nFlags from './shared/i18n/i18n-flags.vue';
import I18nNumber from './shared/i18n/i18n-number.vue';
import GoBackButton from './shared/utils/GoBackButton.vue';


app.component('go-back-button', GoBackButton);
app.component('skeleton-text', SkeletonText);
app.component('app-i18n-flags', I18nFlags)
app.component('i18n-number', I18nNumber)
app.component('master-layout', MasterLayout);
app.component('alarm-datetime-modal', AlarmDatetimeModal);
app.component('hub-item', HubItem);
app.component('note-item', NoteItem);
app.component('peripheral-connection', PeripheralConnection);
app.component('peripheral-item', PeripheralItem);
app.component('peripheral-chips', PeripheralChips);
app.component('peripheral-icon', PeripheralIcon);
app.component('peripheral-metrics', PeripheralMetrics);
app.component('queen-marker-icon', QueenMarkerIcon);
app.component('scale-tare-item', ScaleTareItem);

// import utils from '@/shared/mixins/utils'
// app.mixin(utils)



app.use(VueGoogleMaps, {
  load: {
    key: config.google_key
  },
  //autobindAllEvents: true,
})

import VueSocketIO from 'vue-3-socket.io'

const options = {
  transports: ['websocket'],
  path: '/sio',
  timeout: 5000,
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 5000, // 10000
  reconnectionDelayMax: 15000, // 60000
}

let connection = window.location.origin
if (config.backendSIO) {
  connection = config.backendSIO
}


app.use(new VueSocketIO({
  debug: true,
  connection,
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_'
  },
  options
}))


// Added by the CLI
import './registerServiceWorker';


i18nInit()

configure({
  generateMessage: context => {
    const key = `vv_${context.field}_${context.rule?.name}`
    return i18n(key);
  },
});

router.isReady().then(() => {
  app.mount('#app');
});

