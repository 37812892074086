
import { caretBack } from "ionicons/icons";

import { IonBackButton } from "@ionic/vue";
import { defineComponent, computed, ref, PropType, toRefs } from "vue";

export default defineComponent({
  name: "go-back-button",
  props: {},
  components: { IonBackButton },
  computed: {},
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },

  setup() {
    return { caretBack };
  },
});
