
import { I18nUtil } from '@/shared/i18n/i18n-util';
import { getLanguages } from '@/i18n';
import {
  defineComponent,
  computed,
  ref,
  PropType,
  toRefs,
} from "vue";


export default defineComponent({
  name: 'app-i18n-flags',

  computed: {
    languages() {
      return getLanguages();
    },
  },

  methods: {
    doChangeLanguage(language:string) {
      I18nUtil.doChangeLanguage(language);
    },
  },
});
