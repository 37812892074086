import * as yup from 'yup';
import GenericField from '@/shared/fields/generic-field';
import { i18n } from '@/i18n';

export default class DecimalField extends GenericField {

  scale: number | undefined;


  constructor(
    name: string,
    label: string,
    config: {
      placeholder?: any;
      hint?: any;
      required?: any;
      min?: any;
      max?: any;
      scale?: number | undefined;
    } = {},
  ) {
    super(name, label);
    this.scale = config.scale;
  }

  forPresenter(value: any) {
    if (!value) {
      return value;
    }
    if (this.scale === undefined || this.scale === null) {
      return value;
    }
    return Number(value).toFixed(this.scale);
  }

  forExport() {
    return yup
      .mixed()
      .label(this.label)
      .transform((value) => this.forPresenter(value));
  }

}
