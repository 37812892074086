
import { defineComponent, ref, PropType } from "vue";
import { Peripheral} from "@/models/arniaperfetta";

import { PeripheralService } from "@/services/peripheral-service";
import { Utils } from "@/shared/utils";
import { I18nUtil } from "@/shared/i18n/i18n-util";

export default defineComponent({
  name: "hub-item",

  props: {
    item: {
      type: Object as PropType<Peripheral>,
      required: true,
    },
    item_class: {
      type: String,
      default: "item-danger",
    },
  },

  components: {},
  computed: {
    connection_icon() {
      return Utils.connection_icon(this.item);
    },
    connection_color() {
      return Utils.connection_color(this.item);
    },

  },
  methods: {
    formatDateTime(value: any) {
      return I18nUtil.formatDateTime(value);
    },
    details() {
      this.$emit("details", this.item);
    },
  },
  setup() {
    return {};
  },
});
