import { setLanguageCode, i18n, i18nExists } from '@/i18n';
import moment, { Moment } from 'moment';
import { TAGS } from '@/models/constants';
import { PeripheralType } from '@/models/arniaperfetta';
export class I18nUtil {
  
  static timeframe_options(): any[] {
    const options = [];
    options.push({ value: 'interval', name: i18n("timeframe_modal_interval") });
    options.push({ value: 'days', name: i18n("timeframe_modal_day") });
    options.push({ value: 'weeks', name: i18n("timeframe_modal_week") });
    options.push({ value: 'months', name: i18n("timeframe_modal_month") });
    return options;
  }

  // static peripheral_type_enum(value: PeripheralType) {
  //   return i18n(`enum.${PeripheralType[value]}`)
  // }



  static doChangeLanguage(language: string) {
    setLanguageCode(language);
    window.location.reload();
  }

  static formatRange(values: any, timeframe: string = 'interval') {
    if (values) {
      const start = moment(values[0]);
      const end = moment(values[1]);
      const isSameMonth = start.isSame(end, 'month');
      const isSameYear = start.isSame(end, 'year');

      if (start.isSame(end, 'day')) {
        return start.format('ll');
      }
      else if (isSameMonth && isSameYear) {
        let days = `${start.format('D')} - ${end.format('D')}`
        if (timeframe === 'months' && this.exactMonth(start, end)) {
          days = '';
        }
        return `${days} ${start.format('MMM')} ${start.format('YYYY')}`;
      }
      else if (isSameYear) {
        return `${start.format('D')} ${start.format('MMM')} - ${end.format('D')} ${end.format('MMM')} ${start.format('YYYY')}`;
      }
      else {
        return `${start.format('ll')} - ${end.format('ll')} `;

      }

    }
    return undefined;
  }

  static exactMonth(start: Moment, end: Moment) {
    return start.isSame(start.startOf('month'), 'day') && end.isSame(end.endOf('month'), 'day')
  }

  static formatDateTime(value: any, format: string = 'YYYY-MM-DD HH:mm') {
    if (value) {
      return moment(value).format(format);
    }
    return undefined;
  }

  static formatDate(value: any, format: string = 'YYYY-MM-DD') {
    if (value) {
      return moment(value).format(format);
    }
    return undefined;
  }

  static tags_options(values: string[] = []): any[] {
    if (!values.length) {
      values = TAGS;
    }
    const options = [];
    for (const value of values) {
      options.push({ value, name: i18n(`tag.${value}`) });
    }
    return options;
  }

  static tags_render(values: string[] = []) {
    const names = [];
    for (const value of values) {
      if (i18nExists(`enum.${value}`)) {
        names.push(i18n(`enum.${value}`));
      }
      else if (i18nExists(`tag.${value}`)) {
        names.push(i18n(`tag.${value}`));
      }
      else {
        names.push(value);
      }
    }
    names.sort();
    return names.join(', ');
  }
}
