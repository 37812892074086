
import { getLocaleCode} from "@/i18n";
import { defineComponent, computed, ref, PropType, toRefs } from "vue";

export default defineComponent({
  name: "i18n-number",
  props: {
    value: Number,
    color: { type: String , default: undefined},
    symbol: { type: String, default: "" },
    default: { type: String, default: "0" },
    fraction: { type: Number, default: 0 },
    signed: { type: Boolean, default: false },
  },
  setup(props) {
    let _val = "0";
    let color = props.color;

    const maximumFractionDigits = props.fraction;
    const signDisplay = props.signed ? "exceptZero" : "auto";

    const nf = new Intl.NumberFormat(getLocaleCode(), {
      signDisplay,
      maximumFractionDigits,
    });


    _val = props.value ? nf.format(props.value) : props.default;

    if (props.signed && props.value) {
      color = props.value > 0 ? "limegreen" : "danger";
    }

    // if (props.signed && props.value) {
    //   _val = props.value > 0 ? '+' + props.value?.toFixed(props.fraction) : props.value?.toFixed(props.fraction);
    //   color = props.value > 0 ? "limegreen" : "danger";
    // }
    // else {
    //   _val = props.value ? props.value?.toFixed(props.fraction) : props.default;
    // }
    const number = `${_val} ${props.symbol}`.trim();
    return { number, color};
  },
});
