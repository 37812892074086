import { Peripheral, Hub, Sentinel, Scale, Meteo, AlarmsConfig, AlarmType, AlarmStatus, QueenMarker, PeripheralType, Thresholds } from '@/models/arniaperfetta';
import moment from "moment";
import { ICONS } from '@/models/constants';
import authAxios from '@/shared/axios/auth-axios';
import { ModelMapper } from '@/services/model-mapper'
import { i18n } from '@/i18n';

export class PeripheralService {


    static async list(): Promise<Peripheral[]> {
        const params = {
            //   filter,
            //   orderBy,
            //   limit,
            //   offset,
        };

        const response = await authAxios.get(
            `/peripheral`,
            {
                params,
            },
        );
        return ModelMapper.decodePeripherals(response.data);
    }


    static async markers(): Promise<any[]> {
        const params = {};
        const response = await authAxios.get(
            `/markers`,
            {
                params,
            },
        );
        return ModelMapper.decodeMarkers(response.data);
    }



    static async updatePeripheralMonitoring(id: number, data: any) {
        const body = { data };
        const response = await authAxios.put(
            `/peripheral/${id}/monitoring`,
            body,
        );
        return response.data;
    }

    static async updatePeripheralName(id: number, payload: any) {
        const data: any = {}
        if (payload.name) {
            data['description'] = payload.name
        }
        if (payload.hub_name) {
            data['hub_description'] = payload.hub_name
        }
        const body = { data };
        const response = await authAxios.put(
            `/peripheral/${id}/name`,
            body,
        );
        return response.data;
    }

    static async updatePeripheralColor(item: Peripheral) {
        const data: any = ModelMapper.encode_color(item)
        const body = { data };
        const response = await authAxios.put(
            `/peripheral/${item.id}/color`,
            body,
        );
        return response.data;
    }



    static async updatePeripheralAlarmsConfig(id: number, alarm_config: AlarmsConfig) {
        const data: any = ModelMapper.encode_alarm_config(alarm_config)
        const body = { data };
        const response = await authAxios.put(
            `/peripheral/${id}/config_alarms`,
            body,
        );
        return response.data;
    }

    static async updatePeripheralAlarms(id: number, data: any) {
        const body = { data };
        const response = await authAxios.put(
            `/peripheral/${id}/reset_alarms`,
            body,
        );
        return response.data;
    }

    static async resetPeripheralAlarms(item: Peripheral, payload: any) {
        const alarms = await this.updatePeripheralAlarms(item.id, ModelMapper.encode_alarm_reset(payload));
        ModelMapper.decode_active_alarm(alarms, item);
        // const alarmed = item.alarms.filter(el => el.status === AlarmStatus.ALARMED)
        // if (alarmed.length === 1) {
        //     alarms = await this.updatePeripheralAlarms(item.id, ModelMapper.encode_alarm_reset(['alarm']));
        //     ModelMapper.decode_active_alarm(alarms, item);        
        // }
    }


    static async updatePeripheralThresholds(id: number, thresholds: Thresholds) {
        const data: any = ModelMapper.encode_thresholds(thresholds)
        const body = { data };
        const response = await authAxios.put(
            `/peripheral/${id}/thresholds`,
            body,
        );
        return response.data;
    }

    static async updatePeripheralTare(item: Peripheral) {
        const data: any = ModelMapper.encode_tare(item)
        const body = { data };
        const response = await authAxios.put(
            `/peripheral/${item.id}/tare`,
            body,
        );
        return response.data;
    }

    static peripheral_name(item: Peripheral, hub_name = false): string | undefined {
        if (!item) {
            return undefined
        }
        if (hub_name && item.id === item.parent) {
            return item.hub_name
        }
        else {
            return item.name
        }
        return undefined;
    }

    static queen_marker(peripheral: Peripheral): QueenMarker | undefined {
        let marker: QueenMarker = undefined!;
        if (peripheral.type === PeripheralType.BMETEO || peripheral.type === PeripheralType.BSCALE) {
            return undefined;
        }
        if (peripheral.type === PeripheralType.BHUB) {
            marker = (<Hub>peripheral).queen_marker
        }
        if (peripheral.type === PeripheralType.BSENTINEL) {
            marker = (<Sentinel>peripheral).queen_marker
        }
        if (!marker) {
            marker = QueenMarker.WHITE;
        }
        return marker
    }



    static dummy(key: number): Peripheral {
        let dummy: Peripheral = new Hub(key)
        if (key < 10000000) {
            dummy = new Hub(key)
        }
        else if (key >= 10000000 && key < 20000000) {
            dummy = new Sentinel(key)
        }
        else if (key >= 20000000 && key < 30000000) {
            dummy = new Meteo(key)
        }
        else if (key >= 30000000 && key < 40000000) {
            dummy = new Scale(key)
        }
        else if (key >= 40000000 && key < 50000000) {
            dummy = new Scale(key)
        }
        dummy.name = '';
        return dummy
    }

    static set_queen_marker(peripheral: Peripheral, marker: QueenMarker | undefined) {
        if (peripheral.type === PeripheralType.BHUB) {
            (<Hub>peripheral).queen_marker = marker!
        }
        if (peripheral.type === PeripheralType.BSENTINEL) {
            (<Sentinel>peripheral).queen_marker = marker!
        }
    }



    static peripheral_chips(peripheral: Peripheral): any {
        const chips: any = {};

        // chips['battery'] = {}
        // chips['battery']['icon'] = ICONS['battery_normal'];
        // chips['battery']['color'] = 'success';

        for (const alarm of peripheral.alarms) {
            const key = AlarmType[alarm.type].toLowerCase();
            chips[key] = {}
            chips[key]['icon'] = alarm.icon;
            chips[key]['color'] = alarm.color;
            //console.log( `${key} ${alarm.color} ${alarm.value} ${alarm.icon} ` )
            // switch (alarm.status) {
            //     case AlarmStatus.ALARMED:
            //         chips[key]['color'] = 'danger';
            //         break;
            //     case AlarmStatus.ALERTED:
            //         chips[key]['color'] = 'warning';
            //         break;
            //     case AlarmStatus.NORMAL:
            //         chips[key]['color'] = 'success';
            //         break;
            //     default:
            //         break;
            // }
        }

        if (chips['battery']) {
            if (peripheral.type === PeripheralType.BSCALE) {
                chips['battery']['label'] = i18n('details_scale_battery');
            }
        }

        if (chips['battery_sibling']) {
            if (peripheral.type === PeripheralType.BSCALE) {
                chips['battery_sibling']['label'] = i18n('details_scale_battery_sibling');
            }
        }

        if (chips['temperature']) {
            chips['temperature']['value'] = peripheral.metrics.temperature
        }

        if (chips['humidity']) {
            chips['humidity']['value'] = peripheral.metrics.humidity
        }

        if (chips['weight']) {
            chips['weight']['value'] = peripheral.metrics.weight
            chips['weight']['diff'] = peripheral.metrics.weight_diff
            if (peripheral.metrics.weight_diff) {
                if (peripheral.metrics.weight_diff > 0) {
                    chips['weight']['diff_color'] = 'limegreen'
                }
                else {
                    chips['weight']['diff_color'] = 'danger'
                }
            }
        }

        return chips;
    }

    static parent_status(parent: Peripheral, peripherals: Peripheral[]): any {
        let found = undefined
        let status = parent.status;
        const children = peripherals.filter(el => el.parent === parent.id && el.id != parent.id)
        found = children.filter(el => el.status === AlarmStatus.ALARMED)
        if (found.length > 0) {
            status = AlarmStatus.ALARMED;
        }
        found = children.filter(el => el.status === AlarmStatus.ALERTED)
        if (found.length > 0 && status !== AlarmStatus.ALARMED) {
            status = AlarmStatus.ALERTED;
        }
        return status;
    }

}