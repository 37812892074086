
import { defineComponent, ref, PropType } from "vue";
import { useStore, MutationTypes, ActionTypes } from "@/store";
import { Peripheral } from "@/models/arniaperfetta";
import { PeripheralService } from "@/services/peripheral-service";
import { pencil } from "ionicons/icons";
import { Utils } from "@/shared/utils";
import { I18nUtil } from "@/shared/i18n/i18n-util";

export default defineComponent({
  name: "peripheral-item",

  props: {
    item: {
      type: Object as PropType<Peripheral>,
      required: true,
    },
    item_class: {
      type: String,
      default: "item-danger",
    },

  },

  components: {},
  computed: {

    queen_marker() {
      return Utils.queen_marker_color(
        PeripheralService.queen_marker(this.item)
      );
    },
    columns() {
      return PeripheralService.queen_marker(this.item) ? 8 : 10
    },
  },
  methods: {

    details() {
      this.$emit("details", this.item);
    },
    async editName() {
      await this.store.dispatch(ActionTypes.doUpdateName, {
        item: this.item,
        is_hub: false,
      });
    },
  },
  setup() {
    const store = useStore();

    return { store, pencil };
  },
});
