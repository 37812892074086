import * as yup from 'yup';
import GenericField from '@/shared/fields/generic-field';
import { i18n } from '@/i18n';
import isInteger from 'lodash/isInteger';

export default class IntegerField extends GenericField {


  constructor(
    name: string,
    label: string,
    config: {
      placeholder?: any;
      hint?: any;
      required?: any;
      min?: any;
      max?: any;
    } = {},
  ) {
    super(name, label);
  }

  forPresenter(value: any) {
    return value;
  }


  forExport() {
    return yup.mixed().label(this.label);
  }

}
