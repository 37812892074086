import authAxios from '@/shared/axios/auth-axios';
import { ModelMapper } from '@/services/model-mapper'
import { GlobalConfiguration } from '@/models/arniaperfetta';


export class SettingsService {

  static async findGlobalConfiguration():Promise<GlobalConfiguration> {
    const response = await authAxios.get(`/settings/global_configuration`);
    return ModelMapper.decode_global_config(response.data);
  }

  static async updateGlobalConfiguration(settings: any):Promise<GlobalConfiguration> {
    const body = {
      settings,
    };
    const response = await authAxios.put(
      `/settings/global_configuration`,
      body,
    );
    return ModelMapper.decode_global_config(response.data);
  }

}
