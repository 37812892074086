
/* eslint-disable */

import { Peripheral, PeripheralType, Scale } from "@/models/arniaperfetta";
import { MAX_HIVES } from "@/models/constants";

import { modalController } from "@ionic/vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { closeCircle, save } from "ionicons/icons";
import {
  defineComponent,
  ref,
  PropType,
  toRefs,
  reactive,
  computed,
  onUnmounted,
  inject,
} from "vue";
import { i18n } from "@/i18n";
import { useStore, MutationTypes, ActionTypes } from "@/store";

type FormDataType = {
  hives?: number | null;
  last_hour?: number | null;
};

export default defineComponent({
  name: "scale-tare-modal",
  props: {
    item: {
      type: Object as PropType<Scale>,
      required: true,
    },
  },

  components: { Form, Field, ErrorMessage },
  computed: {},
  methods: {
    async dismiss() {
      await modalController.dismiss();
    },
    i18n(code: any) {
      return i18n(code);
    },
  },

  setup(props) {
    const bus: any = inject("emitter");
    const dismiss = async function () {
      const modal = await modalController.getTop();
      if (modal) {
        await modal.dismiss();
      }
    };

    bus.on("route-changed", dismiss);
    onUnmounted(() => {
      bus.off("route-changed", dismiss);
    });
    const title = i18n("details_scale_tare_action");
    const { item } = toRefs(props);
    const store = useStore();
    const progress = computed(() => store.state.progress);
    const formData = ref<FormDataType>({
      hives: item.value.tare.hives,
      last_hour: item.value.tare.last_hour,
    });
    const methods = reactive({
      async onSubmit(data: FormDataType) {
        const value: Scale = item.value as Scale;
        value.tare.hives = data.hives!;
        value.tare.last_hour = data.last_hour!;
        await store.dispatch(ActionTypes.doUpdateTare, value);
        if (!store.state.error) {
          await modalController.dismiss();
        }
      },
    });

    const hours_between = (value: any) => {
      if (!value || value < 19 || value > 23) {
        return i18n("vv_last_hour_required");
      }
      return true;
    };

    const hives_value = (value: any) => {
      // if (value > 0) {
      //   return true;
      // }
      if (!value || value > MAX_HIVES || value <= 0) {
        return i18n("vv_hives_required");
      }
      return true;
    };

    return {
      ...toRefs(methods),
      progress,
      formData,
      title,
      closeCircle,
      save,
      hours_between,
      hives_value,
    };
  },
});
