<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="ion-text-center">
        <ion-buttons slot="start">
          <go-back-button />
          <!-- <ion-button @click="goBack">
          <ion-icon slot="icon-only" :icon="caretBack" />
          </ion-button> -->
        </ion-buttons>

        <ion-buttons slot="end">
          <ion-button disabled="true">
            <ion-icon slot="icon-only"  />
          </ion-button>
        </ion-buttons>
        
        <ion-title>{{ pageTitle }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="wrapper">
        <div class="content">
      <slot></slot>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>

import {
  caretBack
} from "ionicons/icons";
import { defineComponent } from "vue";
export default defineComponent( {
  name: "master-layout",
  components: {

  },
  props: ["pageTitle"],
  setup() {
    return {
      caretBack
    };
  },
   methods: {
    goBack() {
      this.$router.go(-1)
    }
   }
});
</script>

<style scoped>

</style>