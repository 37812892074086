
import { defineComponent, ref, PropType, toRefs } from "vue";



export default defineComponent({
  name: "skeleton-text",

  props: {},

  components: {},
  computed: {

  },
  methods: {

  },
  setup(props) {
    return {};
  },
});
