
import moment from "moment";
import { i18n } from "@/i18n";
import { arrowDown, arrowUp } from "ionicons/icons";
import { Peripheral, PeripheralType } from "@/models/arniaperfetta";
import { PeripheralService } from "@/services/peripheral-service";
import { defineComponent, onMounted, ref, toRefs, PropType } from "vue";
import { useStore, MutationTypes, ActionTypes } from "@/store";
export default defineComponent({
  name: "peripheral-metrics",
  props: {
    item: {
      type: Object as PropType<Peripheral>,
      required: true,
    },
  },
  components: {},
  computed: {
    scale_metrics_validity() {
      return (
        this.scale_metrics &&
        this.scale_metrics.weight_daily &&
        this.scale_metrics.weight_daily.hours
      );
    },
  },
  methods: {
    formatDate(value: any) {
      if (value) {
        return moment(value).format("YYYY-MM-DD");
      }
      return undefined;
    },

    formatShortDate(value: any) {
      if (value) {
        return moment(value).format('MMM DD');
      }
      return undefined;
    },

    i18n(code: any) {
      return i18n(code);
    },
  },
  setup(props) {
    const { item } = toRefs(props);
    const peripheral: Peripheral = item.value;
    let metrics = undefined;
    let meteo_metrics = undefined;
    let scale_metrics = undefined;

    if (
      peripheral.type === PeripheralType.BHUB ||
      peripheral.type === PeripheralType.BSENTINEL
    ) {
      metrics = ref(peripheral.metrics);

      const store = useStore();
      const item: Peripheral = store.state.peripherals.find(
        (el) => el.type === PeripheralType.BMETEO && el.parent === peripheral.parent
      )!;
      if (item) {
        meteo_metrics = ref(item.metrics);
      }
    } else if (peripheral.type === PeripheralType.BMETEO) {
      meteo_metrics = ref(peripheral.metrics);
    } else if (peripheral.type === PeripheralType.BSCALE) {
      scale_metrics = ref(peripheral.metrics);
    }

    // const is_meteo = is_sentinel || peripheral.type === PeripheralType.BMETEO;
    // const is_scale = is_sentinel || peripheral.type === PeripheralType.BSCALE;

    return { arrowDown, arrowUp, meteo_metrics, scale_metrics, metrics };
  },
});
