import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-288108a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_peripheral_icon = _resolveComponent("peripheral-icon")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_queen_marker_icon = _resolveComponent("queen-marker-icon")!
  const _component_peripheral_connection = _resolveComponent("peripheral-connection")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_peripheral_chips = _resolveComponent("peripheral-chips")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    button: "",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.details())),
    detail: "",
    class: _normalizeClass(_ctx.item_class)
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, { class: "ion-align-items-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, { size: "2" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_peripheral_icon, { item: _ctx.item }, null, 8, ["item"])
                  ])
                ]),
                _: 1
              }),
              (_ctx.queen_marker)
                ? (_openBlock(), _createBlock(_component_ion_col, {
                    key: 0,
                    size: "2"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_queen_marker_icon, { color: _ctx.queen_marker }, null, 8, ["color"])
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_col, { size: _ctx.columns }, {
                default: _withCtx(() => [
                  _createVNode(_component_peripheral_connection, { item: _ctx.item }, null, 8, ["item"])
                ]),
                _: 1
              }, 8, ["size"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, { class: "ion-align-items-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_peripheral_chips, { item: _ctx.item }, null, 8, ["item"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}