import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { h } from 'vue'
import { AuthToken } from '@/shared/auth/auth-token';
import { modalController, alertController } from "@ionic/vue";
//import { useStore, MutationTypes, ActionTypes } from "@/store";

import { store } from '@/store';

//
import EventBus from '@/shared/event';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/logout',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginPage.vue'),
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/home',
    name: 'home-page',
    component: () => import('@/views/HomePage.vue')
  },
  {
    path: '/arniaperfetta',
    name: 'arnia-perfetta-page',
    component: () => import('@/views/ArniaPerfettaPage.vue')
  },
  {
    path: '/config',
    name: 'config',
    component: () => import('@/views/ConfigPage.vue'),
    meta: {
      onlyOwner: true
    }
  },
  {
    path: '/notes',
    name: 'notes',
    component: () => import('@/views/CalendarPage.vue')
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('@/views/MapPage.vue'),
    meta: {
      onlyOwner: true
    }

  },
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/AccountPage.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('@/views/ServicePage.vue'),
    meta: {
      onlyOwner: true
    }
  },

  {
    path: '/hub/:id',
    name: 'hub-page',
    component: () => import('@/views/HubPage.vue'),
    props: true,
  },  
  {
    path: '/peripheral/:id/details',
    name: 'details-page',
    component: () => import('@/views/DetailsPage.vue'),
    props: true,
  },  

  {
    path: '/peripheral/:id/charts',
    name: 'charts-page',
    component: () => import('@/views/ChartsPage.vue'),
    props: true,
  },

  {
    path: '/peripheral/:id/notes/:type',
    name: 'peripheral-notes',
    component: () => import('@/views/NotesPage.vue'),
    props: true,
  },

  {
    path: '/settings/:name',
    name: 'settings',
    component: () => import('@/views/SettingsPage.vue'),
    props: true,
    meta: {
      onlyOwner: true
    }
  },

]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {

  EventBus.trigger('route-changed', undefined)

  // const alert = await alertController.getTop()
  // if (alert) {
  //   await alertController.dismiss()
  // }
  // const modal = await modalController.getTop()
  // if (modal) {
  //   await modalController.dismiss()
  // }

  const isOwner = store.state.owner;
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(
      record => record.meta.onlyWhenLoggedOut
  );

  const onlyOwner = to.matched.some(
    record => record.meta.onlyOwner
  );

  const loggedIn = !!AuthToken.get();
  if (!isPublic && !loggedIn) {
    return next({
      path: "/login",
      query: { redirect: to.fullPath }
    });
  }

  if (loggedIn && onlyWhenLoggedOut) {
    return next("/home");
  }

  if (!isOwner && onlyOwner) {
    return next("/home");
  }

  next();
});


export default router
